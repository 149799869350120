import * as types from "./types";
import * as API_URL from "../../../env";

export const salesDashboard = (payload) => ({
    type: types.SALES_DASHBOARD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/dashboard/sales-dashboard",
        method: "POST",
        body: payload
    },
});

export const customerDashboard = (payload) => ({
    type: types.CUSTOMER_DASHBOARD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/dashboard/customer-dashboard",
        method: "POST",
        body: payload
    },
});

export const questionDashboard = (payload) => ({
    type: types.QUESTION_DASHBOARD,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/dashboard/question-dashboard",
        method: "POST",
        body: payload
    },
});