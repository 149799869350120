export const FETCH_CATEGORY_LIST = "category/FETCH_CATEGORY_LIST";
export const FETCH_CATEGORY_LIST_COMPLETED = "category/FETCH_CATEGORY_LIST_COMPLETED";
export const FETCH_CATEGORY_LIST_FAILED = "category/FETCH_CATEGORY_LIST_FAILED";

export const INSERT_CATEGORY = "category/INSERT_CATEGORY";
export const INSERT_CATEGORY_COMPLETED = "categoryINSERT_CATEGORY_COMPLETED";
export const INSERT_CATEGORY_FAILED = "category/INSERT_CATEGORY_FAILED";

export const UPDATE_CATEGORY = "category/UPDATE_CATEGORY";
export const UPDATE_CATEGORY_COMPLETED = "categoryUPDATE_CATEGORY_COMPLETED";
export const UPDATE_CATEGORY_FAILED = "category/UPDATE_CATEGORY_FAILED";


export const FETCH_MASTER_CATEGORY_LIST = "category/FETCH_MASTER_CATEGORY_LIST";
export const FETCH_MASTER_CATEGORY_LIST_COMPLETED = "category/FETCH_MASTER_CATEGORY_LIST_COMPLETED";
export const FETCH_MASTER_CATEGORY_LIST_FAILED = "category/FETCH_MASTER_CATEGORY_LIST_FAILED";