import * as types from "./types";
import * as API_URL from "../../../env";

export const getProductRenewalPriceList = (payload) => ({
    type: types.FETCH_PRODUCT_RENEWAL_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/productRenewal/get-product-renewal-price",
        method: "GET",
        body: payload
    },
});

export const getProductNameList = (payload) => ({
    type: types.FETCH_PRODUCT_RENEWAL_NAME_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/productRenewal/get-product-name",
        method: "GET",
        body: payload
    },
});

export const addProductRenewalPrice = (payload) => ({
    type: types.INSERT_PRODUCT_RENEWAL_PRICE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/productRenewal/add-product-pricing",
        method: "POST",
        body: payload
    },
});

export const updateProductRenewal = (renewal) => ({
    type: types.UPDATE_PRODUCT_RENEWAL,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/productRenewal/update-product-renewal-status",
        method: "PUT",
        body: renewal
    },
});

export const updateProductRenewalStatus = (renewal) => ({
    type: types.UPDATE_PRODUCT_RENEWAL_STATUS,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/productRenewal/update-product-renewal-status",
        method: "PUT",
        body: renewal
    },
});

export const deleteProductRenewal = (payload) => ({
    type: types.DELETE_PRODUCT_RENEWAL,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/productRenewal/delete-product-renewal",
        method: "DELETE",
        body: payload
    },
});