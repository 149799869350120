import React, { Fragment } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Form,
  Row,
} from "reactstrap";
import { H5 } from "../../AbstractElements";
import Pagination from "../Pagination";
import "../../assets/scss/pagination.css";
import excelImg from "../../assets/images/datatable/excel.png";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import { customerOperations } from "../../state/ducks/customer";
import { masterOperations } from "../../state/ducks/master";
import viewIcon from "../../assets/images/datatable/list-details.png";
import { formatDateAndTime, getLanguageName } from "../../utils/helper";
import * as moment from 'moment';
class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: "1",
      language: [],
      customerData: [],
      giftCustomerData: [],
      activeUsers: 0,
      inactiveUsers: 0,
      activeCustomerData: [],
      inactiveCustomerData: [],
      defaultSearchData: [],
      originalActiveCustomerData: [],
      originalInactiveCustomerData: [],
      originalDefaultSearchData: [],
      searchData: "",
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: "asc"
    };
  }


  formatDateAndTime(ydate) {
    const formattedDate = moment(ydate).format('DD-MM-YYYY hh.mm.ss')
    return formattedDate
  }

  async componentDidMount() {
    try {
      this.callListCustomers();
      this.loadLocalStorageData();

    } catch (err) {
      console.log(err);
    }
  }

  loadLocalStorageData = async () => {
    // Store Language
    let language = localStorage.getItem("language");
    if (language) {
      language = JSON.parse(language);
      this.setState({ language });
    } else {
      let response = await this.props.listLanguage();
      if (response.payload.length !== 0) {
        const languages = JSON.stringify(response.payload);
        localStorage.setItem("language", languages);
        language = JSON.parse(languages);
        this.setState({ language });
      }
    }
  };
  generateExcelData = () => {
    const { BasicTab, defaultSearchData, activeCustomerData, inactiveCustomerData } = this.state;
    
    // Get the correct data source based on current tab and search
    let dataToExport;
    
    // If search is active, use defaultSearchData (which contains search results)
    if (this.state.searchData) {
      dataToExport = defaultSearchData;
    } else {
      // If no search, use data based on current tab
      switch (BasicTab) {
        case "1": // All Customers
          dataToExport = defaultSearchData;
          break;
        case "2": // Active Customers
          dataToExport = activeCustomerData;
          break;
        case "3": // Inactive Customers
          dataToExport = inactiveCustomerData;
          break;
        default:
          dataToExport = defaultSearchData;
      }
    }
  
    // Get current page data only
    const startIndex = (this.state.currentPage - 1) * this.state.itemsPerPage;
    const endIndex = startIndex + this.state.itemsPerPage;
    const currentPageData = dataToExport.slice(startIndex, endIndex);
  
    // Create Excel data
    const xlsxData = [
      ["Id","Name", "Book User", "Email", "Creation Date", "Note", "Language", "Language Name"]
    ];
  
    // Add only current page data to Excel
    for (const data of currentPageData) {
      const dataRow = [
        data.id,
        data.name,
        data.book_user,
        data.email,
        formatDateAndTime(data.created_date),
        data.note,
        data.language_id,
        getLanguageName(data.language_id),
      ];
      xlsxData.push(dataRow);
    }
  
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    return workbook;
  };
  // generateExcelData = () => {
  //   const { defaultSearchData } = this.state;
  //   const xlsxData = [
  //     ["Name", "Book User", "Email", "Creation Date", "Note", "Language"],
  //   ];
  //   for (const data of defaultSearchData) {
  //     const dataRow = [
  //       data.name,
  //       data.book_user,
  //       data.email,
  //       formatDateAndTime(data.created_date),
  //       data.note,
  //       data.language_id,
  //       getLanguageName(data.language_id),
  //     ];
  //     xlsxData.push(dataRow);
  //   }
  //   const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   return workbook;
  // };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, "customer.xlsx");
  };

  handleViewDetailsClick = (e) => {
    localStorage.setItem(
      "selectedCustomerId",
      e.target.getAttribute("data-test-id")
    );
    window.open("/customers-management/customer-details/", "_self");
  };

  handlePageChange = async (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber
      }
    );
  };

  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    let defaultSearchData = this.state.originalDefaultSearchData;
    let activeCustomerData = this.state.originalActiveCustomerData;
    let inactiveCustomerData = this.state.originalInactiveCustomerData;

    if (searchData.length > 0) {
      defaultSearchData = defaultSearchData.filter(customer =>
        customer.name.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.email.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.created_date.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.language_id.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.book_user.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.note.toString().toLowerCase().includes(searchData.toLowerCase())
      );
      activeCustomerData = activeCustomerData.filter(customer =>
        customer.name.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.email.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.created_date.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.language_id.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.book_user.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.note.toString().toLowerCase().includes(searchData.toLowerCase())
      );
      inactiveCustomerData = inactiveCustomerData.filter(customer =>
        customer.name.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.email.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.created_date.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.language_id.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.book_user.toString().toLowerCase().includes(searchData.toLowerCase()) ||
        customer.note.toString().toLowerCase().includes(searchData.toLowerCase())
      );
    }
    this.setState({
      searchData,
      defaultSearchData,
      activeCustomerData,
      inactiveCustomerData,
      currentPage: 1
    });
  };


  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder =
      column === sortedColumn && sortOrder === "asc" ? "desc" : "asc";

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  handleTabClick = async (e) => {
    const basicTab = e.target.getAttribute("data-test-id");
    this.setState({
      currentPage: 1,
      BasicTab: basicTab
    })
  };

  callListCustomers = async () => {
    const response = await this.props.listCustomer();
    let myDefaultSearchData = response.payload;

    // let myDefaultSearchData = [];
    // let index = 1;
    // for (let i = 0; i < 10; i++) {
    //   for (const key in response.payload) {
    //     const element = response.payload[key];
    //     myDefaultSearchData.push({
    //       ...element,
    //       myId: index
    //     });
    //     index++;
    //   }
    // }
    this.setState({
      defaultSearchData: myDefaultSearchData,
      originalDefaultSearchData: myDefaultSearchData
    }, () => {
      this.setState({
        activeCustomerData: this.filterCustomersByActivity("Active"),
        inactiveCustomerData: this.filterCustomersByActivity("Inactive"),
        originalActiveCustomerData: this.filterCustomersByActivity("Active"),
        originalInactiveCustomerData: this.filterCustomersByActivity("Inactive"),
      })
    });
  };

  filterCustomersByActivity = (activity) => {
    const { defaultSearchData } = this.state;
    return defaultSearchData.filter(customer => customer.customer_activity === activity);
  };

  render() {
    const {
      BasicTab,
      activeCustomerData,
      inactiveCustomerData,
      defaultSearchData,
      currentPage,
      itemsPerPage,
      sortedColumn,
      sortOrder,
      searchData
    } = this.state;
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    // For Pagination on Active Customers
    const indexOfLastActiveItem = currentPage * itemsPerPage;
    const indexOfFirstActiveItem = indexOfLastActiveItem - itemsPerPage;
    const currentActiveData = activeCustomerData.slice(
      indexOfFirstActiveItem,
      indexOfLastActiveItem
    );
    // For Pagination on Inactive Customers
    const indexOfLastInactiveItem = currentPage * itemsPerPage;
    const indexOfFirstInactiveItem = indexOfLastInactiveItem - itemsPerPage;
    const currentInactiveData = inactiveCustomerData.slice(
      indexOfFirstInactiveItem,
      indexOfLastInactiveItem
    );

    return (
      <Fragment>
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">Customers</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <H5>Customers</H5>
                  <img
                    src={excelImg}
                    width="35"
                    height="35"
                    alt="excel"
                    onClick={this.handleDownload}
                  ></img>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="9"></Col>
                      <Col md="3">
                        <div className="job-filter">
                          <div className="faq-form">
                            <Input
                              type="text"
                              placeholder="Search"
                              value={searchData}
                              onChange={this.DefaultSearchChange}
                            />
                            <Search className="search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        href="#javascript"
                        className={BasicTab === "1" ? "active" : ""}
                        data-test-id={1}
                        onClick={this.handleTabClick}
                      >
                        All Customers ({defaultSearchData.length})
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#javascript"
                        className={BasicTab === "2" ? "active" : ""}
                        data-test-id={2}
                        onClick={this.handleTabClick}
                      >
                        Active Customers ({activeCustomerData.length})
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#javascript"
                        className={BasicTab === "3" ? "active" : ""}
                        data-test-id={3}
                        onClick={this.handleTabClick}
                      >
                        Inactive Customers ({inactiveCustomerData.length})
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={BasicTab}>
                    <TabPane className="fade show" tabId="1">
                      <Table
                        ref="tbl"
                        striped
                        hover
                        responsive
                        bordered
                        id="data-table-zero"
                      >
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort("name")}>
                              Name{" "}
                              {sortedColumn === "name" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() => this.handleTableSort("book_user")}
                            >
                              Book User{" "}
                              {sortedColumn === "book_user" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() => this.handleTableSort("user_email")}
                            >
                              Email{" "}
                              {sortedColumn === "user_email" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            {/* <th onClick={() => this.handleTableSort('phone_no')}>Phone {sortedColumn === 'phone_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                            <th
                              onClick={() =>
                                this.handleTableSort("created_date")
                              }
                            >
                              Creation Date{" "}
                              {sortedColumn === "created_date" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th onClick={() => this.handleTableSort("note")}>
                              Note{" "}
                              {sortedColumn === "note" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.handleTableSort("language_id")
                              }
                            >
                              Language{" "}
                              {sortedColumn === "language_id" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) => (
                            <tr key={i}>
                              <td>{data.name}</td>
                              <td>
                                {data.book_user === "Owner" ? (
                                  <span className="badge badge-light-info">
                                    Owner
                                  </span>
                                ) : data.book_user === "Buyer" ? (
                                  <span className="badge badge-light-primary">
                                    Buyer
                                  </span>
                                ) : data.book_user === "Buyer Owner" ? (
                                  <span>
                                    <span className="badge badge-light-primary">
                                      Buyer
                                    </span>
                                    <span className="badge badge-light-info">
                                      Owner
                                    </span>
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </td>
                              <td>{data.email}</td>
                              {/* <td>{data.phone_no}</td> */}
                              <td>{this.formatDateAndTime(data.created_date)}</td>
                              <td>{data.note}</td>
                              <td>{data.language_id}</td>
                              {/* <td>{getLanguageName(data.language_id)}</td> */}
                              <td>
                                <img
                                  src={viewIcon}
                                  alt="view"
                                  height={19}
                                  width={19}
                                  data-test-id={data.id}
                                  onClick={this.handleViewDetailsClick}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane className="fade show" tabId="2">
                      <Table
                        ref="tb1"
                        striped
                        hover
                        responsive
                        bordered
                        id="data-table-zero"
                      >
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort("name")}>
                              Name{" "}
                              {sortedColumn === "name" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() => this.handleTableSort("book_user")}
                            >
                              Book User{" "}
                              {sortedColumn === "book_user" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() => this.handleTableSort("user_email")}
                            >
                              Email{" "}
                              {sortedColumn === "user_email" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            {/* <th onClick={() => this.handleTableSort('phone_no')}>Phone {sortedColumn === 'phone_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                            <th
                              onClick={() =>
                                this.handleTableSort("created_date")
                              }
                            >
                              Creation Date{" "}
                              {sortedColumn === "created_date" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th onClick={() => this.handleTableSort("note")}>
                              Note{" "}
                              {sortedColumn === "note" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.handleTableSort("language_id")
                              }
                            >
                              Language{" "}
                              {sortedColumn === "language_id" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentActiveData.map((data, i) => (
                            <tr key={i}>
                              <td>{data.name}</td>
                              <td>
                                {data.book_user === "Owner" ? (
                                  <span className="badge badge-light-info">
                                    Owner
                                  </span>
                                ) : data.book_user === "Buyer" ? (
                                  <span className="badge badge-light-primary">
                                    Buyer
                                  </span>
                                ) : data.book_user === "Buyer Owner" ? (
                                  <span>
                                    <span className="badge badge-light-primary">
                                      Buyer
                                    </span>
                                    <span className="badge badge-light-info">
                                      Owner
                                    </span>
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </td>
                              <td>{data.email}</td>
                              {/* <td>{data.phone_no}</td> */}
                              <td>{this.formatDateAndTime(data.created_date)}</td>
                              <td>{data.note}</td>
                              <td>{data.language_id}</td>
                              <td>
                                <img
                                  src={viewIcon}
                                  alt="view"
                                  height={19}
                                  width={19}
                                  data-test-id={data.id}
                                  onClick={this.handleViewDetailsClick}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane className="fade show" tabId="3">
                      <Table
                        ref="tbl"
                        striped
                        hover
                        responsive
                        bordered
                        id="data-table-zero"
                      >
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort("name")}>
                              Name{" "}
                              {sortedColumn === "name" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() => this.handleTableSort("book_user")}
                            >
                              Book User{" "}
                              {sortedColumn === "book_user" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() => this.handleTableSort("user_email")}
                            >
                              Email{" "}
                              {sortedColumn === "user_email" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            {/* <th onClick={() => this.handleTableSort('phone_no')}>Phone {sortedColumn === 'phone_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                            <th
                              onClick={() =>
                                this.handleTableSort("created_date")
                              }
                            >
                              Creation Date{" "}
                              {sortedColumn === "created_date" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th onClick={() => this.handleTableSort("note")}>
                              Note{" "}
                              {sortedColumn === "note" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.handleTableSort("language_id")
                              }
                            >
                              Language{" "}
                              {sortedColumn === "language_id" && (
                                <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                              )}
                            </th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentInactiveData.map((data, i) => (
                            <tr key={i}>
                              <td>{data.name}</td>
                              <td>
                                {data.book_user === "Owner" ? (
                                  <span className="badge badge-light-info">
                                    Owner
                                  </span>
                                ) : data.book_user === "Buyer" ? (
                                  <span className="badge badge-light-primary">
                                    Buyer
                                  </span>
                                ) : data.book_user === "Buyer Owner" ? (
                                  <span>
                                    <span className="badge badge-light-primary">
                                      Buyer
                                    </span>
                                    <span className="badge badge-light-info">
                                      Owner
                                    </span>
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </td>
                              <td>{data.email}</td>
                              {/* <td>{data.phone_no}</td> */}
                              <td>{this.formatDateAndTime(data.created_date)}</td>
                              <td>{data.note}</td>
                              <td>{data.language_id}</td>
                              <td>
                                <img
                                  src={viewIcon}
                                  alt="view"
                                  height={19}
                                  width={19}
                                  data-test-id={data.id}
                                  onClick={this.handleViewDetailsClick}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                  <CardFooter>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={Math.ceil((BasicTab === "1" ? defaultSearchData.length : BasicTab === "2" ?
                        activeCustomerData.length :
                        inactiveCustomerData.length) / 10)}
                      onPageChange={this.handlePageChange}
                    />
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  listCustomer: customerOperations.listCustomer,
  listLanguage: masterOperations.listLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
