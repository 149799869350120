import { Col, Row, Table, CardBody, Card, Media, CardFooter } from "reactstrap";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { H3, H4, H6, P, Image, Btn } from "../../AbstractElements";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import logo from "../../assets/images/logo/logo.png";
import user1 from "../../assets/images/user/1.jpg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { customerOperations } from "../../state/ducks/customer";
import { connect } from "react-redux";
import { Button } from "reactstrap";
class CustomerReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomerData: {},
      orderReceiptData: {},
    };
  }

  async componentDidMount() {
    // Get selected order ID from localStorage
    const customerId = localStorage.getItem("selectedCustomerId");
    const orderReceiptData = JSON.parse(
      localStorage.getItem("orderReceiptData")
    );
    const response = await this.props.getCustomersDetails({
      customerId: customerId,
    });
    console.log(response.payload[0]);
    this.setState({
      selectedCustomerData: response.payload[0],
      orderReceiptData: orderReceiptData,
    });
  }
  handleDownload = () => {
    const { orderReceiptData,selectedCustomerData } = this.state;
    const doc = new jsPDF();
    // Add Logo
    doc.addImage(logo, "PNG", 10, 30, 60, 20);
    // Add Title
    doc.setFontSize(18);
    doc.text("Order Receipt", 80, 20);
    // Customer Info
    doc.setFontSize(12);
    doc.text(
      `Customer Name: ${orderReceiptData.book_user_buyer_name}`,
      10,
      60
    );
    doc.text(
      `Address: ${selectedCustomerData.address}`,
      10,
      70
    );
    doc.text(`Order Date: ${orderReceiptData.date}`, 10, 80);
    // Order Items Table
    const items = orderReceiptData.order_items.map(item => [
      item.order_item_name,  // Item Name
      item.order_item_id,    // Reference No.
      `£${parseFloat(item.price).toFixed(2)}`, // Price
    ]);
    if (items.length > 0) {
      doc.autoTable({
        startY: 100,
        head: [["Item Name", "Reference No.", "Amount"]],
        body: items,
      });
    }
    // Subtotal and Total
    const total = orderReceiptData.order_items.reduce((sum, item) => sum + parseFloat(item.price), 0);
    doc.text(`Total: £${total.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 20);
    // Save the PDF
    doc.save("order_receipt.pdf");
  };
  
  render() {
    const { selectedCustomerData, orderReceiptData } = this.state;
    return (
      <Fragment>
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link style={{ color: "#000" }} to="/customers-management/">
                    Customers
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    style={{ color: "#000" }}
                    to="/customers-management/customer-details/"
                  >
                    Customer Details
                  </Link>
                </li>
                <li className="breadcrumb-item active">Receipt</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm="12" xl="12" className="xl-100">
          <Card>
            <CardBody>
              <div className="invoice">
                <div>
                  <Row className="invo-header">
                    <Col sm="6">
                      <Media>
                        <div className="media-left">
                          <Image
                            attrImage={{
                              className: "media-object img-120",
                              src: logo,
                              alt: "",
                            }}
                          />
                          <br />
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <br />
                          <span>&nbsp;&nbsp;&nbsp;</span>
                        </div>
                      </Media>
                    </Col>
                    <Col sm="6">
                      <div className="text-md-end text-xs-center">
                        <H3>
                          {orderReceiptData.book_user_buyer_name}{" "}
                          {/* {selectedCustomerData.last_name} */}
                        </H3>
                        <P>
                          Receipt No.: #{orderReceiptData.order_id}
                          <br />
                          Payment Date:{" "}
                          <span className="digits">
                            {orderReceiptData.date}
                          </span>
                        </P>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="invo-profile">
                    <Col xl="4">
                      <div className="invo-profile-left">
                        <Media>
                          <Media body className="m-l-20">
                            <H4 attrH4={{ className: "media-heading f-w-600" }}>
                              {orderReceiptData.book_user_owner_name}
                            </H4>
                            <P></P>
                          </Media>
                        </Media>
                      </div>
                    </Col>
                    <Col xl="8">
                      <div className="text-md-end">
                        <H6>Address</H6>
                        <P>{selectedCustomerData.address}</P>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive invoice-table" id="table">
                    <Table bordered striped>
                      <thead>
                        <tr>
                          <th className="item">
                            <H6 attrH6={{ className: "p-2 mb-0" }}>
                              Item Name
                            </H6>
                          </th>
                          <th className="quantity">
                            <H6 attrH6={{ className: "p-2 mb-0" }}>
                              Reference No.
                            </H6>
                          </th>
                          <th className="Rate">
                            <H6 attrH6={{ className: "p-2 mb-0" }}>Amount</H6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderReceiptData.order_items &&
                          orderReceiptData.order_items.map((item, index) => (
                            <tr key={index}>
                              <td>{item.order_item_name}</td>
                              <td>{orderReceiptData.order_id}</td>
                              <td>£{parseFloat(item.price).toFixed(2)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>

                  <div
                    className="table-responsive invoice-table"
                    id="table"
                  ></div>
                  <Row className="mt-3">
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className="text-md-end">
                        <P>
                          <b>Total:</b> £
                          {parseFloat(orderReceiptData.amount).toFixed(2)}
                        </P>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Col sm="12" className="text-center">
                <Button
                  color="primary"
                  className="me-2"
                  onClick={this.handleDownload}
                >
                  Download
                </Button>
                <Link to="/customers-management/customer-details/">
                  <Btn attrBtn={{ color: "primary" }}>Cancel</Btn>
                </Link>
              </Col>
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  getCustomersDetails: customerOperations.getCustomersDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerReceipt);
