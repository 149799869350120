export const FETCH_DISCOUNT_LIST = "discount/FETCH_DISCOUNT_LIST";
export const FETCH_DISCOUNT_LIST_COMPLETED = "discount/FETCH_DISCOUNT_LIST_COMPLETED";
export const FETCH_DISCOUNT_LIST_FAILED = "discount/FETCH_DISCOUNT_LIST_FAILED";

export const INSERT_DISCOUNT = "discount/INSERT_DISCOUNT";
export const INSERT_DISCOUNT_COMPLETED = "discount/INSERT_DISCOUNT_COMPLETED";
export const INSERT_DISCOUNT_FAILED = "discount/INSERT_DISCOUNT_FAILED";

export const UPDATE_DISCOUNT = "discount/UPDATE_DISCOUNT";
export const UPDATE_DISCOUNT_COMPLETED = "discount/UPDATE_DISCOUNT_COMPLETED";
export const UPDATE_DISCOUNT_FAILED = "discount/UPDATE_DISCOUNT_FAILED";

export const UPDATE_DISCOUNT_STATUS = "discount/UPDATE_DISCOUNT_STATUS";
export const UPDATE_DISCOUNT_STATUS_COMPLETED = "discount/UPDATE_DISCOUNT_STATUS_COMPLETED";
export const UPDATE_DISCOUNT__STATUS_FAILED = "discount/UPDATE_DISCOUNT_STATUS_FAILED";

export const DELETE_DISCOUNT = "discount/DELETE_DISCOUNT";
export const DELETE_DISCOUNT_COMPLETED = "discount/DELETE_DISCOUNT_COMPLETED";
export const DELETE_DISCOUNT_FAILED = "discount/DELETE_DISCOUNT_FAILED";