export const FETCH_PRODUCT_RENEWAL_LIST = "productRenewal/FETCH_RENEWAL_LIST";
export const FETCH_PRODUCT_RENEWAL_LIST_COMPLETED = "productRenewal/FETCH_RENEWAL_LIST_COMPLETED";
export const FETCH_PRODUCT_RENEWAL_LIST_FAILED = "productRenewal/FETCH_RENEWAL_LIST_FAILED";

export const FETCH_PRODUCT_RENEWAL_NAME_LIST = "productRenewal/PRODUCT_RENEWAL_NAME_LIST";
export const FETCH_PRODUCT_RENEWAL_NAME_LIST_COMPLETED = "productRenewal/PRODUCT_RENEWAL_NAME_LIST_COMPLETED";
export const FETCH_PRODUCT_RENEWAL_NAME_LIST_FAILED = "productRenewal/PRODUCT_NAME_RENEWAL_LIST_FAILED";

export const INSERT_PRODUCT_RENEWAL_PRICE = "productRenewal/INSERT_PRODUCT_RENEWAL_PRICE";
export const INSERT_PRODUCT_RENEWAL_PRICE_COMPLETED = "productRenewal/INSERT_PRODUCT_RENEWAL_PRICE_COMPLETED";
export const INSERT_PRODUCT_RENEWAL_PRICE_FAILED = "productRenewal/INSERT_PRODUCT_RENEWAL_PRICE_FAILED";

export const UPDATE_PRODUCT_RENEWAL = "productRenewal/UPDATE_RENEWAL";
export const UPDATE_PRODUCT_RENEWAL_COMPLETED = "productRenewal/UPDATE_PRODUCT_RENEWAL_COMPLETED";
export const UPDATE_PRODUCT_RENEWAL_FAILED = "productRenewal/UPDATE_PRODUCT_RENEWAL_FAILED";

export const UPDATE_PRODUCT_RENEWAL_STATUS = "productRenewal/UPDATE_PRODUCT_RENEWAL_STATUS";
export const UPDATE_PRODUCT_RENEWAL_STATUS_COMPLETED = "productRenewal/UPDATE_PRODUCT_RENEWAL_STATUS_COMPLETED";
export const UPDATE_PRODUCT_RENEWAL__STATUS_FAILED = "productRenewal/UPDATE_PRODUCT_RENEWAL_STATUS_FAILED";

export const DELETE_PRODUCT_RENEWAL = "productRenewal/DELETE_PRODUCT_RENEWAL";
export const DELETE_PRODUCT_RENEWAL_COMPLETED = "productRenewal/DELETE_PRODUCT_RENEWAL_COMPLETED";
export const DELETE_PRODUCT_RENEWAL_FAILED = "productRenewal/DELETE_PRODUCT_RENEWAL_FAILED";