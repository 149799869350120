import React from 'react';

class Logout extends React.Component {
    componentDidMount() {
        this.logoutHandler();
    }

    logoutHandler = async () => {
        localStorage.removeItem('login');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('i18nextLng');
        localStorage.removeItem('user');
        localStorage.removeItem('profileURL');
        localStorage.removeItem('token');
        localStorage.removeItem('selectedCustomerId');
        localStorage.removeItem('category');
        localStorage.removeItem('language');
        localStorage.removeItem('selectedQuestionId');
        localStorage.removeItem('selectedOrderId');
        localStorage.removeItem('selectedCategoryId');
        localStorage.removeItem('menus');
        localStorage.removeItem('layout');
        localStorage.removeItem('sidebar_icon_type');
        localStorage.removeItem('default_color');
        localStorage.removeItem('secondary_color');
        localStorage.removeItem('sidebar_layout');
        localStorage.removeItem('animation');
        localStorage.removeItem('auth0_profile');
        localStorage.removeItem('Name');
        localStorage.removeItem('orderData');
        localStorage.removeItem('isViewMode');
        window.open("/login/", "_self");
    };
    render() {
        return (<span></span>);
    }
}

export default Logout;