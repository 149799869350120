import * as moment from 'moment';

export function formatDate(yDate) {
    const formattedDate = moment(yDate).format("YYYY-MM-DD")
    return formattedDate;
}

export function formatDateAndTime(ydate) {
    const formattedDate = moment(ydate).format('DD-MM-YYYY hh.mm.ss')
    return formattedDate
}

export function formatDateAndTimeLocal(yDate) {
    const date = new Date(yDate);
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'UTC'
    };
    const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }, options);
    return formattedDate;
}

export function getLanguageName(id) {
    let name = '';
    let language = localStorage.getItem('language');
    if (language) {
        language = JSON.parse(language);
        let foundData = language.find(data => data.id === id);
        if (foundData) {
            name = foundData.language_name;
        }
    }
    return name;
}