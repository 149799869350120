import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Container, Card, CardBody, CardHeader, Col, Row,Table } from 'reactstrap';
import {  H5, H4, H6, UL, LI, P } from '../../AbstractElements';

import SvgIcon from '../../Components/Common/Component/SvgIcon';

import { dashboardOperations } from '../../state/ducks/dashboard';
import { connect } from 'react-redux';

  class Dashboard extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        selectedData: {},
        RecentSalesData:[],
        ActivityData:[]
      };
    }


    async componentDidMount() {
      try {
        // get today data
        const response = await this.props.Dashboard({ filterType: "days", filterDate: "today" });
        console.log("response====>>",response);
        this.setState({ selectedData: response.payload });
        this.setState({ RecentSalesData: response.payload.recentSales });
        this.setState({ ActivityData: response.payload.recentActivities });
      } catch (err) {
        console.log(err);
      }
    }
  // async componentDidMount() {
  //   try {
  //     const options = {
  //       url: API_URL.BACKEND_SERVICE_API_URL + `/dashboard/dashboard-status`,  
  //       method: "GET",
  //       headers: {
  //         authorization: localStorage.getItem('token')  
  //       }
  //     };
  //     let response = await request(options);
  //     console.log("response====>>",response);
  //     this.setState({ selectedData: response.payload });
  //     this.setState({ RecentSalesData: response.payload.recentSales });
  //     this.setState({ ActivityData: response.payload.recentActivities });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  render() {
    const {selectedData,RecentSalesData,ActivityData} = this.state;
  return (
    <Fragment>
      <div className='page-title'>
        <Row>
          <Col xs='12'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to="/dashboard/">
                  <SvgIcon iconId='stroke-home' />
                </Link>
              </li>
              <li className='breadcrumb-item active'>Dashboard</li>
            </ol>
          </Col>
        </Row>
      </div>
      <Container fluid={true}>
        <Row>
          <CardHeader className='d-flex align-items-center justify-content-between'>
            <H5>Dashboard</H5>
          </CardHeader>
          <Col xxl='auto' xl='6' sm='6' className='box-col-6'>
            <Row>
              <Col xl='12'>
                <Card className='widget-1'>
                  <CardBody>
                    <div className='widget-content'>
                      <div className={`widget-round primary`}>
                        <div className='bg-round'>
                          <SvgIcon className='svg-fill' iconId='tag' />
                          <SvgIcon className='half-circle svg-fill' iconId='halfcircle' />
                        </div>
                      </div>
                      <div style={{ visibility: 'hidden' }}>
                        <H4>200</H4>
                        <span className='f-light'>Sales</span>
                      </div>
                      <div style={{ visibility: 'hidden' }}>
                        <H4>200</H4>
                        <span className='f-light'>Sales</span>
                      </div>
                      <div style={{ visibility: 'hidden' }}>
                        <H4>200</H4>
                        <span className='f-light'>Sales</span>
                      </div>
                      <div>
                        <H4>£{parseFloat(selectedData.totalSales).toFixed(2)}</H4>
                        <span className='f-light'>Sales</span>
                      </div>
                    </div>
                    <div className={`font-primary f-w-500`}>
                      <i className={`icon-arrow-100 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                      <span>{`${100 < 50 ? '-' : '+'}${100}%`}</span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xxl='auto' xl='6' sm='6' className='box-col-6'>
            <Row>
              <Col xl='12'>
                <Card className='widget-1'>
                  <CardBody>
                    <div className='widget-content'>
                      <div className={`widget-round success`}>
                        <div className='bg-round'>
                          <SvgIcon className='svg-fill' iconId='rate' />
                          <SvgIcon className='half-circle svg-fill' iconId='halfcircle' />
                        </div>
                      </div>
                      <div style={{ visibility: 'hidden' }}>
                        <H4>200</H4>
                        <span className='f-light'>Sales</span>
                      </div>
                      <div style={{ visibility: 'hidden' }}>
                        <H4>200</H4>
                        <span className='f-light'>Sales</span>
                      </div>
                      <div style={{ visibility: 'hidden' }}>
                        <H4>200</H4>
                        <span className='f-light'>Sales</span>
                      </div>
                      <div>
                        <H4>{selectedData.totalOrders}</H4>
                        <span className='f-light'>Orders</span>
                      </div>
                    </div>
                    <div className={`font-success f-w-500`}>
                      <i className={`icon-arrow-100 < 50 ? 'down' : 'up'} icon-rotate me-1`} />
                      <span>{`${100 < 50 ? '-' : '+'}${100}%`}</span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xxl='4' xl='6' md='6' sm='7' className='notification box-col-6'>
      <Card className='height-equal'>
        <CardHeader className='card-no-border'>
          <div className='header-top'>
            <H5 attrH5={{ className: 'm-0' }}>Activity</H5>
            <div className='card-header-right-icon'>
              {/* <DropdownCommon icon={false} options={DailyDropdown} btn={{ caret: true }} /> */}
            </div>
          </div>
        </CardHeader>
        <CardBody className='pt-0'>
          <UL>
            {ActivityData.map((item, i) => (
              <LI key={i} attrLI={{ className: 'd-flex' }}>
                <div className={`activity-dot-$}`} />
                <div className='w-100 ms-3'>
                  <P attrPara={{ className: 'd-flex justify-content-between mb-2' }}>
                    <span className='date-content light-background'>{item.date}</span>
                    <span>{item.timeAgo}</span>
                  </P>
                  <H6>
                    {item.title}
                    <span className='dot-notification' />
                  </H6>
                  <p className='f-light'>{item.description}</p>
                </div>
              </LI>
            ))}
          </UL>
        </CardBody>
      </Card>
    </Col>
    <Col xxl='4' xl='6' md='6' sm='7' className='notification box-col-6'>
      <Card className='height-equal'>
        <CardHeader className='card-no-border'>
        <div className='header-top'>
              <H5 attrH5={{ className: 'm-0' }}>Recent Sales</H5>
              <div className='card-header-right-icon'>
                {/* <DropdownCommon icon={false} options={DailyDropdown} btn={{ caret: true }} /> */}
              </div>
            </div>
        </CardHeader>
        <CardBody className='pt-0'>
        {/* <div className='appointment-table'> */}
        <div >
              <Table borderless responsive>
                <tbody>
                  {RecentSalesData.map((item, i) => (
                    <tr key={i}>
                      <td className='d-block f-w-500'>
                      <div className='d-block f-w-500'>
                          {item.customer}
                        </div>
                        <span className='f-light'>{item.timeAgo}</span>
                      </td>
                      <td className='text-end'>
                        <P attrPara={{ className: 'm-0 font-success' }}>{item.amount}</P>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
        </CardBody>
      </Card>
    </Col>
                
          {/* <ActivityCard /> */}
          {/* <RecentSales /> */}
        </Row>
      </Container>
    </Fragment>
  );
};
};

const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  Dashboard: dashboardOperations.Dashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
