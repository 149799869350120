import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { H5, H4, Btn } from "../../AbstractElements";
import excelImg from "../../assets/images/datatable/excel.png";
import userCircle from "../../assets/images/datatable/user-circle.png";
import list from "../../assets/images/datatable/list.png";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import { formatDateAndTime } from "../../utils/helper";
import Pagination from "../Pagination";
import "../../assets/scss/pagination.css";
import editIcon from "../../assets/images/datatable/written-paper.png";
// import deleteIcon from '../../assets/images/datatable/delete.png';
import { categoryOperations } from "../../state/ducks/category";
import { questionOperations } from "../../state/ducks/question";
import deleteIcon from "../../assets/images/datatable/waste-bin.png";
import reactiveIcon from "../../assets/images/datatable/waste-bin-r.png";
class CategoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: "1",
      selectedCategoryData: {},
      allCategoryData: [],
      activeCategoryData: [],
      inActiveCategoryData: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: "asc",
      modalNoteOpen: false,
    };
  }

  async componentDidMount() {
    // for all category
    const categoryId = localStorage.getItem("selectedCategoryId");
    const response = await this.props.listCategoryDetails({
      categoryId: categoryId,
    });
    this.setState({ selectedCategoryData: response.payload });
    this.setState({ allCategoryData: response.payload.questions });
    // for active category
    const successResponse = await this.props.listCategoryDetails({
      categoryId: categoryId,
      search: '{"status":1}',
    });
    this.setState({ activeCategoryData: successResponse.payload.questions });
    // for inactive category
    const pendingResponse = await this.props.listCategoryDetails({
      categoryId: categoryId,
      search: '{"status":0}',
    });
    this.setState({ inActiveCategoryData: pendingResponse.payload.questions });
  }

  generateExcelData = () => {
    const { allCategoryData } = this.state;
    const xlsxData = [["Question", "Status", "Last Edit", "Category"]];
    for (const data of allCategoryData) {
      const dataRow = [
        data.question,
        data.status,
        formatDateAndTime(data.last_edit),
        data.category,
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, "category_details.xlsx");
  };

  handleEditClick = (e) => {
    localStorage.setItem(
      "selectedQuestionId",
      e.target.getAttribute("data-test-id")
    );
    window.open("/question-category/question-edit/", "_self");
  };

  handleDeleteClick = async (e) => {
    try {
      let questionId = e.target.getAttribute("data-test-id");
      let categoryId = this.state.selectedCategoryData.id;

      if (!questionId || !categoryId) {
        toast.error("Invalid question or category ID", { autoClose: 5000 });
        return;
      }
      const response = await this.props.deleteQuestion({
        question_id: questionId,
        category_id: categoryId,
      });
      toast.success(response.message, { autoClose: 10000 });
      window.open("/question-category/category-details/", "_self");
    } catch (err) {
      console.log(err);
    }
  };

    onUpdate = async (e) => {
      e.preventDefault();
      try {
        const { allCategoryData } = this.state;
        const questionId = Number(e.target.getAttribute("data-test-id"));
        const question = allCategoryData.find((cat) => cat.id === questionId);
        let newStatus = question.status === "Active" ? "Inactive" : "Active";
        let updateResponse = await this.props.updateQuestionStatus({
          id: questionId,
          status: newStatus === "Active" ? 1 : 0,
        });
  
        // Update the local state with the new status
        this.setState({
          allCategoryData: allCategoryData.map(
            (cat) => (cat.id === questionId ? { ...cat, status: newStatus } : cat) // Update status of the clicked category
          ),
        });
  
        toast.success(updateResponse.message, { autoClose: 10000 });
        window.open("/question-category/question/", "_self");
      } catch (err) {
        toast.error(err.response ? err.response.message : "Update failed", {
          autoClose: 10000,
        });
        console.log(err);
      }
    };
  
  updateNotes = async (e) => {
    e.preventDefault();
    try {
      const { selectedCategoryData } = this.state;
      const response = await this.props.updateCategoryNote({
        id: selectedCategoryData.id,
        notes: selectedCategoryData.notes,
      });
      if (response.status === 200) {
        this.setState((prevState) => ({
          modalNoteOpen: false,
        }));
      }
      toast.success("Notes updated successfully.", { autoClose: 10000 });
      window.open("/question-category/category-details/", "_self");
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  handleInput = async (e) => {
    try {
      let tid = e.target.id;
      const { selectedCategoryData } = this.state;
      switch (tid) {
        case "note":
          const notes = e.target.value;
          this.setState({
            selectedCategoryData: {
              ...selectedCategoryData,
              notes,
            },
          });
          break;
        default:
          console.log("No Matching target found in handleInput");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleShowNotes = () => {
    this.setState((prevState) => ({
      modalNoteOpen: !prevState.modalNoteOpen,
    }));
  };

  onCloseNotesModal = () => {
    this.setState((prevState) => ({
      modalNoteOpen: false,
    }));
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleTableSort = (column) => {
    const { allCategoryData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder =
      column === sortedColumn && sortOrder === "asc" ? "desc" : "asc";

    // Sort the data based on the selected column and sortOrder
    const sortedData = allCategoryData.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      allCategoryData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const {
      BasicTab,
      modalNoteOpen,
      selectedCategoryData,
      activeCategoryData,
      inActiveCategoryData,
      allCategoryData,
      currentPage,
      itemsPerPage,
      sortedColumn,
      sortOrder,
    } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = allCategoryData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    const totalPages = Math.ceil(allCategoryData.length / itemsPerPage);
    return (
      <Fragment>
        <ToastContainer />
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link style={{ color: "#000" }} to="/question-category/">
                    Question Category
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Question Category Details
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm="12" xl="12" className="xl-100">
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <H4>{selectedCategoryData.category_name} Category</H4>
              <img
                src={excelImg}
                width="35"
                height="35"
                alt="excel"
                onClick={this.handleDownload}
              ></img>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={userCircle}
                      width="22"
                      height="22"
                      alt="excel"
                      style={{ marginRight: "10px" }}
                    />
                    <H5 style={{ margin: 0 }}>Question Category Details</H5>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#595959",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">Category Name</div>
                      <div className="mb-2">Added Date</div>
                      <div className="mb-2">Last Updated Date</div>
                      <div className="mb-2">Status</div>
                      <div className="mb-5">Notes</div>
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">
                        {selectedCategoryData.category_name}
                      </div>
                      <div className="mb-2">
                        {formatDateAndTime(selectedCategoryData.added_date)}
                      </div>
                      <div className="mb-2">
                        {formatDateAndTime(
                          selectedCategoryData.last_update_date
                        )}
                      </div>
                      <div className="mb-2">{selectedCategoryData.status}</div>
                      <div className="mb-2">
                        <Input
                          type="textarea"
                          placeholder="Notes"
                          value={selectedCategoryData.notes || ""}
                          onClick={this.handleShowNotes}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Modal
                  isOpen={modalNoteOpen}
                  toggle={() => this.handleShowNotes()}
                  style={{ marginTop: "200px" }}
                >
                  <Form onSubmit={this.updateNotes}>
                    <span
                      style={{ textAlign: "right", padding: "5px" }}
                      onClick={this.onCloseNotesModal}
                    >
                      Close
                    </span>
                    <ModalBody>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "20px",
                          padding: "7px",
                          background: "#890077",
                          color: "#fff",
                          height: "30px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      >
                        Update Notes
                      </div>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="8">
                          <Label>Notes</Label>
                          <Input
                            style={{ fontSize: "10px", padding: "11px" }}
                            type="textarea"
                            id="note"
                            name="note"
                            value={selectedCategoryData.notes || ""}
                            onChange={this.handleInput}
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Col md="4"></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Col>
                        <Btn attrBtn={{ color: "primary", type: "submit" }}>
                          Update
                        </Btn>
                        &nbsp;&nbsp;
                      </Col>
                    </ModalFooter>
                  </Form>
                </Modal>
              </Form>
              <Row>
                <Col md="10" style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={list}
                    width="22"
                    height="22"
                    alt="excel"
                    style={{ marginRight: "10px" }}
                  />
                  <H5>List of Question</H5>
                </Col>
                <Col md="2">
                  <div className="d-flex align-items-center gap-3">
                    <Link to="/question-category/question-add/">
                      <Btn
                        attrBtn={{ color: "primary d-flex align-items-center" }}
                      >
                        Add Question
                      </Btn>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "1" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "1" })}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "2" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "2" })}
                  >
                    Active
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "3" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "3" })}
                  >
                    Inactive
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={BasicTab}>
                <TabPane className="fade show" tabId="1">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th onClick={() => this.handleTableSort("question")}>
                          Question{" "}
                          {sortedColumn === "question" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("last_edit")}>
                          Last Edit{" "}
                          {sortedColumn === "last_edit" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("category")}>
                          Category{" "}
                          {sortedColumn === "category" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) => (
                        <tr key={i}>
                          <td>{indexOfFirstItem + i + 1}</td>
                          <td>{data.question}</td>
                          {data.status === "active" ? (
                            <td>
                              <span className="badge badge-light-info">
                                {data.status}
                              </span>
                            </td>
                          ) : (
                            <td>
                              <span className="badge badge-light-primary">
                                {data.status}
                              </span>
                            </td>
                          )}
                          <td>{formatDateAndTime(data.last_edit)}</td>
                          <td>{data.category}</td>
                          <td>
                            <img
                              src={editIcon}
                              alt="edit"
                              height={12}
                              width={12}
                              data-test-id={data.id}
                              onClick={this.handleEditClick}
                            />
                          </td>
                          <td>
                            <img
                              src={
                                data.status === "active"
                                  ? deleteIcon
                                  : reactiveIcon
                              }
                              alt="status icon"
                              height={18}
                              width={18}
                              data-test-id={data.id}
                              onClick={this.onUpdate}
                            />
                          </td>
                          {/* <td><img src={deleteIcon} alt='delete' height={17} width={17} data-test-id={data.id} onClick={this.handleDeleteClick}/></td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="2">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th onClick={() => this.handleTableSort("question")}>
                          Question{" "}
                          {sortedColumn === "question" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("last_edit")}>
                          Last Edit{" "}
                          {sortedColumn === "last_edit" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("category")}>
                          Category{" "}
                          {sortedColumn === "category" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeCategoryData.map((data, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{data.question}</td>
                          {data.status === "Active" ? (
                            <td>
                              <span className="badge badge-light-info">
                                {data.status}
                              </span>
                            </td>
                          ) : (
                            <td>
                              <span className="badge badge-light-primary">
                                {data.status}
                              </span>
                            </td>
                          )}
                          <td>{formatDateAndTime(data.last_edit)}</td>
                          <td>{data.category}</td>
                          <td>
                            <img
                              src={editIcon}
                              alt="edit"
                              height={12}
                              width={12}
                              data-test-id={data.id}
                              onClick={this.handleEditClick}
                            />
                          </td>
                          <td>
                            <img
                              src={
                                data.status === "Active"
                                  ? deleteIcon
                                  : reactiveIcon
                              }
                              alt="status icon"
                              height={18}
                              width={18}
                              data-test-id={data.id}
                              onClick={this.onUpdate}
                            />
                          </td>
                          {/* <td><img src={deleteIcon} alt='delete' height={17} width={17} data-test-id={data.id} onClick={this.handleDeleteClick}/></td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="3">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th onClick={() => this.handleTableSort("question")}>
                          Question{" "}
                          {sortedColumn === "question" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("last_edit")}>
                          Last Edit{" "}
                          {sortedColumn === "last_edit" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("category")}>
                          Category{" "}
                          {sortedColumn === "category" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inActiveCategoryData.map((data, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{data.question}</td>
                          {data.status === "Active" ? (
                            <td>
                              <span className="badge badge-light-info">
                                {data.status}
                              </span>
                            </td>
                          ) : (
                            <td>
                              <span className="badge badge-light-primary">
                                {data.status}
                              </span>
                            </td>
                          )}
                          <td>{formatDateAndTime(data.last_edit)}</td>
                          <td>{data.category}</td>
                          <td>
                            <img
                              src={editIcon}
                              alt="edit"
                              height={12}
                              width={12}
                              data-test-id={data.id}
                              onClick={this.handleEditClick}
                            />
                          </td>
                          <td>
                            <img
                              src={
                                data.status === "Active"
                                  ? deleteIcon
                                  : reactiveIcon
                              }
                              alt="status icon"
                              height={18}
                              width={18}
                              data-test-id={data.id}
                              onClick={this.onUpdate}
                            />
                          </td>
                          {/* <td><img src={deleteIcon} alt='delete' height={17} width={17} data-test-id={data.id} onClick={this.handleDeleteClick} /></td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            </CardBody>
            <CardFooter>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={this.handlePageChange}
              />
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  listCategoryDetails: categoryOperations.listCategoryDetails,
  updateCategoryNote: categoryOperations.updateCategoryNote,
  updateQuestionStatus: questionOperations.updateQuestionStatus,
  deleteQuestion: questionOperations.deleteQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetails);
