import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { H5, Btn } from "../../AbstractElements";
import { Row, Col, CardBody, CardFooter, CardHeader, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Save, Cancel } from '../../Constant';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { categoryOperations } from '../../state/ducks/category';

class CategoryAdd extends React.Component {

  onInsert = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        category_title: this.state.categoryTitle,
        status: this.state.statusId,
        notes: this.state.categoryNotes
      }
      const response = await this.props.addCategory(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/question-category/", "_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  handleChange = async (e) => {
    try {
      let tid = e.target.id;
      switch (tid) {
        case 'status':
          this.setState({ statusId: e.target.value });
          break;
        default:
          console.log("No Matching target found");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/question-category/">Question Category</Link>
                </li>
                <li className='breadcrumb-item active'>Add Category</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <div className='edit-profile'>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className='d-flex align-items-center justify-content-between'>
                    <H5>Add Category</H5>
                  </CardHeader>
                  <Form onSubmit={this.onInsert}>
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">Category Title</Label>
                            <Input type="text" placeholder="Enter Category Title" onChange={(e) => this.setState({ categoryTitle: e.target.value })} />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label className="form-label">Category Notes(Internal)</Label>
                            <Input type="textarea" placeholder="Enter Category Notes" onChange={(e) => this.setState({ categoryNotes: e.target.value })} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Btn attrBtn={{ color: "primary", type: "submit" }} >{Save}</Btn>&nbsp;&nbsp;&nbsp;
                      <Link to="/question-category/">
                        <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment >
    )
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  addCategory: categoryOperations.addCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryAdd)