import * as types from "./types";
import * as API_URL from "../../../env";

export const listQuestion = (payload) => ({
    type: types.FETCH_QUESTION_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/question/list-question",
        method: "POST",
        body: payload
    },
});

export const addQuestion = (payload) => ({
    type: types.INSERT_QUESTION,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/question/add-question",
        method: "POST",
        body: payload
    },
});

export const updateQuestion = (question) => ({
    type: types.UPDATE_QUESTION,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/question/update-question",
        method: "PUT",
        body: question
    },
});

export const updateQuestionStatus = (question) => ({
    type: types.UPDATE_QUESTION,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/question/update-question-status",
        method: "PUT",
        body: question
    },
});
export const deleteQuestion = (payload) => ({
    type: types.DELETE_QUESTION,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/question/delete-question",
        method: "DELETE",
        body: payload
    },
});