import React, { Component } from 'react';
import { connect } from 'react-redux';
import { productRenewalPricingOperations } from '../../state/ducks/productRenewalPricing';
import { toast } from 'react-toastify';
import { Btn } from "../../AbstractElements";
import { Row, Col, CardBody, CardFooter, CardHeader, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import { duration } from 'moment';

class RenewalPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: [],
            productData: [],
            formData: {
                product_id: '',
                product_name: '',
                duration_year: '',
                price: '',
            },
            editIndex: null,
        };
    }

    async componentDidMount() {
        try {
            const productNameList = await this.props.getProductNameList();
            const productData = productNameList.payload || [];
            console.log(productData);

            const renewalResponse = await this.props.getProductRenewalPriceList();
            const entries = renewalResponse.payload || [];
            console.log(entries);
            
            this.setState({ productData, entries });
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));
    };

    handleAdd = async (e) => {
        e.preventDefault();
        const { formData } = this.state;
            try {
                await this.props.addProduct({
                    product_id: formData.product_id,
                    product_name: formData.product_name,
                    duration_year: formData.duration_year,
                    price: formData.price,
                });
                toast.success('Renewal added successfully!');
            window.open("/product-renewal-pricing/", "_self");

            } catch (err) {
                console.error('Error adding discount:', err);
                toast.error('Error adding discount.');
            }
        // Reset form data and editIndex after submission
        this.setState({
            formData: {
                product_id: '',
                product_name: '',
                duration_year: '',
                price: '',
            },
        });
    };

    onUpdate = async (e) => {
        e.preventDefault();
        try {
            const { entries } = this.state;
            const productId = Number(e.target.getAttribute('data-test-id'));
            const product = entries.find(dis => dis.id === productId);
            let newStatus = product.status === 1 ? 0 : 1;
            let updateResponse = await this.props.updateProductRenewal({
                id: productId,
                status: newStatus === 1 ? 1 : 0,
            });
            // Update the local state with the new status
            this.setState({
                entries: entries.map(dis =>
                    dis.id === productId ? { ...dis, status: newStatus } : dis // Update status of the clicked category
                ),
            });
            toast.success(updateResponse.message, { autoClose: 10000 });
            window.open("/product-renewal-pricing/", "_self");
        } catch (err) {
            toast.error(err.response ? err.response.message : 'Update failed', { autoClose: 10000 });
            console.log(err);
        }
    };

    render() {
        const { entries, formData, productData } = this.state;
        return (
            <div style={{ padding: '20px' }}>
                <div className='edit-profile'>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <h5>{'Add Product Renewal Pricing'}</h5>
                                </CardHeader>
                                <Form onSubmit={this.handleAdd}>
                                    <CardBody>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <Label className="form-label">
                                                        Product Name
                                                    </Label>
                                                    <Input
                                                        type="select"
                                                        name="product_id"
                                                        value={formData.product_id}
                                                        onChange={this.handleChange}
                                                        style={{ width: '100%' }}
                                                        required
                                                    >
                                                        <option value="">Select Product</option>
                                                        {productData.map((product) => (
                                                            <option key={product.product_id} value={product.product_id}>
                                                                {product.product_name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col><FormGroup>
                                                <Label className="form-label">
                                                    Duration
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="duration_year"
                                                    placeholder="Enter Duration"
                                                    value={formData.duration_year}
                                                    onChange={this.handleChange}
                                                    required
                                                    style={{ width: '100%' }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="form-label">
                                                    Price
                                                </Label>
                                                <Input
                                                    type="number"
                                                    name="price"
                                                    value={formData.price}
                                                    onChange={this.handleChange}
                                                    required
                                                    style={{ width: '100%' }}
                                                />
                                            </FormGroup>
                                        </Row>
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }}>Add</Btn>
                                    </CardFooter>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <h5 style={{ color: "#890077" }}>Product Renewal Pricing list</h5>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Product</th>
                            <th>Duration</th>
                            <th>Price</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entries.length > 0 ? (
                            entries.map((entry, index) => (
                                
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{entry.product_name}</td>
                                    <td>{entry.duration === 1 ? "1 year" : entry.duration === 2 ? "2 Year" : entry.duration === 3 ? "3 year" : "" }</td>
                                    <td>{entry.price}</td>
                                    <td>
                                        <button
                                            className="btn btn-danger btn-sm ml-2"
                                            data-test-id={entry.id}
                                            onClick={this.onUpdate}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    No Entries Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    addProduct: productRenewalPricingOperations.addProductRenewalPrice,
    updateProductRenewal: productRenewalPricingOperations.updateProductRenewal,
    getProductRenewalPriceList: productRenewalPricingOperations.getProductRenewalPriceList,
    getProductNameList : productRenewalPricingOperations.getProductNameList,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenewalPage);
