import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const listReducer = createReducer([])({
    [types.FETCH_DISCOUNT_LIST_COMPLETED]: (state, action) => {
        return action.payload.payload
    },
    [types.INSERT_DISCOUNT_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
    [types.UPDATE_DISCOUNT_COMPLETED]: (state, action) => {
        const discount = action.payload.payload
        return state.map(e => e.id === discount.id ? discount : e)
    },     
    [types.UPDATE_DISCOUNT_STATUS_COMPLETED]: (state, action) => {
        const discount = action.payload.payload
        return state.map(e => e.id === discount.id ? discount : e)
    },  
    [types.DELETE_DISCOUNT_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
});

export default combineReducers({
    list: listReducer
});