import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { H5, H4, Btn } from "../../AbstractElements";
import excelImg from "../../assets/images/datatable/excel.png";
import userCircle from "../../assets/images/datatable/user-circle.png";
import list from "../../assets/images/datatable/list.png";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import { formatDateAndTime } from "../../utils/helper";
import Pagination from "../Pagination";
import "../../assets/scss/pagination.css";
import receipt from "../../assets/images/datatable/receipt.png";
import icon from "../../assets/images/datatable/icon.png";
import { masterOperations } from "../../state/ducks/master";
import { orderOperations } from "../../state/ducks/order";
import { customerOperations } from "../../state/ducks/customer";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { printBookOrderOperations } from "../../state/ducks/printBookOrder";
import jsPDF from "jspdf";
import logo from "../../assets/images/logo/logo.png";

class PrintingBookDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: "1",
      selectedUserData: {},
      selectedPackageData: [],
      orderBookDetail: [],
      additionalOrderDetails: [],
      orderItemsData: [],
      itemData: [],
      successItemsData: [],
      successOrderItem: [],
      pendingItemsData: [],
      processingItemsData: [],
      shippedItemsData: [],
      canceledItemsData: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: "asc",
      modalOpen: false,
      modalNoteOpen: false,
      totalBookCopies: "",
      orderData:{}
    };
  }

  async componentDidMount() {
    // for all orders items
    const orderId = localStorage.getItem("selectedOrderId");
    const aid = orderId;
    const response = await this.props.listPrintBookOrderDetails({
      orderBookId: aid,
    });
    this.setState(
      {
        selectedUserData: response.payload.orderBookDetail,
        selectedPackageData: response.payload.userPackages,
        orderItemsData: response.payload.orderItemDetails,
        totalBookCopies: response.payload.totalBookCopies,
      },
      () => {}
    );
  }

 
  generateExcelData = () => {
    const { orderItemsData } = this.state;
    const xlsxData = [
      [  "Id",
        "Items",
        "First & Last Name",
        "Amount",
        "Status",
        "Print Book Order Date",
        "Types Of Book",
        "Delivery Address",
      ],
    ];
    for (const data of orderItemsData) {
      const dataRow = [
        data.id,
        data.itemName,
        data.first_name + " " + data.last_name,
            data.subtotal,
            data.status,
            formatDateAndTime(data.created_date),
            data.printColor.coverColor,
           data.address + " " + data.address2+ " " +data.city + " " + data.state + " " + data.postcode
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, "Book_details.xlsx");
  };

  handleOrderReceiptClick = (data) => {
    localStorage.setItem('orderData', JSON.stringify(data))
    window.open("/print-book-order/print-book-details/print-order-receipt", "_self");
  };

  // Separate function to generate the PDF as a base64 string
handleGeneratePDF = () => {
  const orderData = JSON.parse(localStorage.getItem("orderData"));
  const doc = new jsPDF();
  // Add Logo (you can skip this if not needed)
  doc.addImage(logo, "PNG", 10, 30, 60, 20);
  // Add Title
  doc.setFontSize(18);
  doc.text("Order Receipt", 80, 20);
  // Customer Info (from orderData)
  doc.setFontSize(12);
  doc.text(
    `Customer Name: ${orderData.first_name} ${orderData.last_name}`,
    10,
    60
  );
  doc.text(
    `Address: ${orderData.address}, ${orderData.city}, ${orderData.state}, ${orderData.postcode}`,
    10,
    70
  );
  doc.text(`Order Date: ${orderData.created_date}`, 10, 90);

  // Order Items Table (Extracting itemName, id, and subtotal from orderData directly)
  const items = [
    [
      orderData.itemName,  // Item Name from orderData
      orderData.id,         // Reference No. from orderData
      `£${parseFloat(orderData.subtotal).toFixed(2)}`, // Subtotal from orderData
    ]
  ];

  // Add table for items
  if (items.length > 0) {
    doc.autoTable({
      startY: 90,
      head: [["Item Name", "Reference No.", "Amount"]],
      body: items,
    });
  }
  // Subtotal and Total (Calculated from the subtotal field in orderData)
  const total = parseFloat(orderData.subtotal); // If there are no additional items, just use subtotal
  doc.text(`Total: £${total.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 20);
  // Generate PDF as base64
  const pdfBase64 = doc.output("datauristring"); // Get PDF as base64 encoded string
  return pdfBase64; // Return base64 PDF to send via email
};

sendEmailWithPDF = async (e) => {
  e.preventDefault();
  try {
    const { selectedUserData } = this.state;
    // Generate PDF and get it as base64
    const pdfBase64 = this.handleGeneratePDF();
    // Call the sendEmail API on your backend to send the email
    const response = await this.props.sendEmail({
      to: selectedUserData.email,
      subject: "Order Details",
      attachment: pdfBase64,
      name: selectedUserData.first_name + " " + selectedUserData.last_name
    });
    if (response.status === 200) {
      this.setState({ modalOpen: false }); // Close modal after email sent
      toast.success("Email sent successfully.", { autoClose: 10000 });
    }
  } catch (err) {
    toast.error("Something went wrong.", { autoClose: 10000 });
    console.error(err);
  }
};

  handleShowDetails = (data) => {
    localStorage.setItem('orderData', JSON.stringify(data))
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  onCloseModal = () => {
    this.setState((prevState) => ({
      modalOpen: false,
    }));
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleTableSort = (column) => {
    const { orderItemsData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder =
      column === sortedColumn && sortOrder === "asc" ? "desc" : "asc";
    // Sort the data based on the selected column and sortOrder
    const sortedData = orderItemsData.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      orderItemsData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  updateNotes = async (e) => {
    e.preventDefault();
    try {
      const orderId = localStorage.getItem("selectedOrderId");
      const aid = parseFloat(orderId);
      const { selectedUserData } = this.state;
      const response = await this.props.updateNote({
        userId: selectedUserData.id,
        id: aid,
        note: selectedUserData.note,
      });
      if (response.status === 200) {
        this.setState((prevState) => ({
          modalNoteOpen: false,
        }));
      }
      toast.success("Notes updated successfully.", { autoClose: 10000 });
      window.open("/order-management/order-details/", "_self");
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  handleInput = async (e) => {
    try {
      let tid = e.target.id;
      const { selectedUserData } = this.state;
      switch (tid) {
        case "note":
          const note = e.target.value;
          this.setState({
            selectedUserData: {
              ...selectedUserData,
              note,
            },
          });
          break;
        default:
          console.log("No Matching target found in handleInput");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleShowNotes = () => {
    this.setState((prevState) => ({
      modalNoteOpen: !prevState.modalNoteOpen,
    }));
  };

  onCloseNotesModal = () => {
    this.setState((prevState) => ({
      modalNoteOpen: false,
    }));
  };

  render() {
    const {
      BasicTab,
      modalOpen,
      modalNoteOpen,
      selectedPackageData,
      selectedUserData,
      orderItemsData,
      currentPage,
      itemsPerPage,
      sortedColumn,
      sortOrder,
      totalBookCopies,
    } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = orderItemsData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(orderItemsData.length / itemsPerPage);

    return (
      <Fragment>
        <ToastContainer />
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link style={{ color: "#000" }} to="/print-book-order/">
                    Printing Book Order List
                  </Link>
                </li>
                <li className="breadcrumb-item active">Printing Book Order</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm="12" xl="12" className="xl-100">
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <H4>Printing Book Order</H4>
              <img
                src={excelImg}
                width="35"
                height="35"
                alt="excel"
                onClick={this.handleDownload}
              ></img>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={userCircle}
                      width="22"
                      height="22"
                      alt="excel"
                      style={{ marginRight: "10px" }}
                    />
                    <H5 style={{ margin: 0 }}>User Details</H5>
                  </Col>

                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={userCircle}
                      width="22"
                      height="22"
                      alt="excel"
                      style={{ marginRight: "10px" }}
                    />
                    <H5 style={{ margin: 0 }}>Package Details</H5>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#595959",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">Name</div>
                      <div className="mb-2">Email</div>
                      <div className="mb-2">Address</div>
                      <div className="mb-2">Total Payment</div>
                      <div className="mb-2">Total Book Copy</div>
                      <div className="mb-2">Print Book Date</div>
                      {/* <div className="mb-2">Notes</div> */}
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">
                        {selectedUserData.user_name || ""}
                      </div>
                      <div className="mb-2">{selectedUserData.email || ""}</div>
                      <div className="mb-2">
                        {selectedUserData.address || ""},{" "}
                        {selectedUserData.city || ""},{" "}
                        {selectedUserData.state || ""},{" "}
                        {selectedUserData.postcode || ""}
                      </div>
                      <div className="mb-2">
                        £{selectedUserData.subtotal || ""}
                      </div>
                      <div className="mb-2">{totalBookCopies || ""}</div>
                      <div className="mb-2">
                        {selectedUserData.created_date || ""}
                      </div>
                      {/* <div className="mb-4">
                        <Input
                          type="textarea"
                          placeholder="Notes"
                          value={selectedUserData.note || ""}
                          onClick={this.handleShowNotes}
                        />
                      </div> */}
                    </div>
                  </Col>

                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#595959",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">Package</div>
                      <div className="mb-2">Purchase Date</div>
                      <div className="mb-2">Book Expiry Date</div>
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 15,
                    }}
                  >
                    {selectedPackageData.map((data, i) => (
                      <div>
                        <div className="mb-2">
                          {data.packages[0].package || ""}
                        </div>
                        <div className="mb-2">{data.orderDate || ""}</div>
                        <div className="mb-2">{data.expiryDate || ""}</div>
                      </div>
                    ))}
                  </Col>
                </Row>
                <Modal
                  isOpen={modalNoteOpen}
                  toggle={() => this.handleShowNotes()}
                  style={{ marginTop: "200px" }}
                >
                  <Form onSubmit={this.updateNotes}>
                    <span
                      style={{ textAlign: "right", padding: "5px" }}
                      onClick={this.onCloseNotesModal}
                    >
                      Close
                    </span>
                    <ModalBody>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "20px",
                          padding: "7px",
                          background: "#890077",
                          color: "#fff",
                          height: "30px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      >
                        Update Notes
                      </div>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="8">
                          <Label>Notes</Label>
                          <Input
                            style={{ fontSize: "10px", padding: "11px" }}
                            type="textarea"
                            id="note"
                            name="note"
                            value={selectedUserData.note || ""}
                            onChange={this.handleInput}
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Col md="4"></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Col>
                        <Btn attrBtn={{ color: "primary", type: "submit" }}>
                          Update
                        </Btn>
                        &nbsp;&nbsp;
                      </Col>
                    </ModalFooter>
                  </Form>
                </Modal>
              </Form>
              <Row>
                <Col md="12" style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={list}
                    width="22"
                    height="22"
                    alt="excel"
                    style={{ marginRight: "10px" }}
                  />
                  <H5 style={{ margin: 0 }}>Order Items</H5>
                </Col>
              </Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "1" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "1" })}
                  >
                    All
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "2" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "2" })}
                  >
                    Order Placed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "3" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "3" })}
                  >
                    Printing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "4" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "4" })}
                  >
                    Transit
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "5" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "5" })}
                  >
                    Completed
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={BasicTab}>
                <TabPane className="fade show" tabId="1">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          ITEMS{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("first_&_last_name")
                          }
                        >
                          First & Last Name{" "}
                          {sortedColumn === "first_&_last_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Print Book Order Date{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("type_of_book")}
                        >
                          Type Of Book{" "}
                          {sortedColumn === "type_of_book" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("delivery_address")
                          }
                        >
                          Delivery Address{" "}
                          {sortedColumn === "delivery_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("book_download")}
                        >
                          Receipt	{" "}
                          {/* {sortedColumn === "book_download" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )} */}
                        </th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) => (
                        <tr key={data.id || i}>
                          <td>{data.itemName}</td>
                          <td>
                            {data.first_name} {data.last_name}
                          </td>
                          <td>{data.id}</td>
                          <td>£{parseFloat(data.subtotal).toFixed(2)}</td>
                          {data.status === "Pending" ? (
                            <td>
                              <span className="badge badge-light-info">
                                {data.status}
                              </span>
                            </td>
                          ) : (
                            <td>
                              <span className="badge badge-light-primary">
                                {data.status}
                              </span>
                            </td>
                          )}
                          <td>{formatDateAndTime(data.created_date)}</td>
                          <td>{data.printColor.coverColor}</td>
                          <td>
                            {data.address} {data.address2} {data.city}{" "}
                            {data.state} {data.postcode}
                          </td>
                          <td>
                            <img
                              src={receipt}
                              alt="receipt"
                              height={15}
                              width={15}
                              onClick={()=>this.handleOrderReceiptClick(data)}
                            />
                          </td>
                          <td>
                            <img
                              src={icon}
                              alt="icon"
                              height={15}
                              width={15}
                              onClick={()=>this.handleShowDetails(data)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
              <Modal
                isOpen={modalOpen}
                toggle={() => this.handleShowDetails()}
                style={{ marginTop: "200px" }}
              >
                <Form onSubmit={this.sendEmailWithPDF}>
                  <span
                    style={{ textAlign: "right", padding: "5px" }}
                    onClick={this.onCloseModal}
                  >
                    Close
                  </span>
                  <ModalBody>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "20px",
                        padding: "7px",
                        background: "#890077",
                        color: "#fff",
                        height: "30px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      Send Email
                    </div>
                    <Row>
                      <Col md="2"></Col>
                      <Col md="8">
                        <Label>Email</Label>
                        <Input
                          style={{ fontSize: "10px", padding: "11px" }}
                          type="text"
                          value={selectedUserData.email}
                          disabled
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Col md="4"></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Col>
                      <Btn attrBtn={{ color: "primary", type: "submit" }}>
                        Send
                      </Btn>
                      &nbsp;&nbsp;
                    </Col>
                  </ModalFooter>
                </Form>
              </Modal>
            </CardBody>
            <CardFooter>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={this.handlePageChange}
              />
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  listPrintBookOrderDetails: printBookOrderOperations.listPrintBookOrderDetails,
  // updateNote: customerOperations.updateNote, 
  sendEmail: masterOperations.sendEmail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintingBookDetails);
