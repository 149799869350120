import { Col, Row, Table, CardBody, Card, Media, CardFooter } from "reactstrap";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { H3, H4, H6, P, Image, Btn } from "../../AbstractElements";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import logo from "../../assets/images/logo/logo.png";
import user1 from "../../assets/images/user/1.jpg";
import { connect } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "reactstrap";
import { orderOperations } from '../../state/ducks/order';
// Update with actual logo path

class OrderReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserData: {},
      orderReceiptData:{},
      itemData:{}
    };
  }

  async componentDidMount() {
    // Get selected order ID from localStorage
    const orderId = localStorage.getItem("selectedOrderId");
    const orderReceiptData = JSON.parse(localStorage.getItem("orderReceipt"));
    const aid = parseFloat(orderId);
    // Fetch data from API
    const response = await this.props.listOrderDetails({
      "orderId": aid,
    });
    // Update state with the API response
    this.setState({
      orderReceiptData: orderReceiptData, 
      itemData: response.payload.items ,
      orderItemsData: response.payload.order_items,
      selectedUserData: response.payload.user_details });
  }

  handleDownload = () => {
    const {selectedUserData, itemData, orderReceiptData} = this.state;
    const doc = new jsPDF();
    // Add Logo
    doc.addImage(logo, "PNG", 10, 30, 60, 20);
    // Add Title
    doc.setFontSize(18);
    doc.text("Print Order Receipt", 80, 20);
    // Customer Info
    doc.setFontSize(12);
    doc.text(
      `Customer Name: ${selectedUserData.name}`,
      10,
      60
    );
    // doc.text(`Email: ${selectedUserData.email}`, 10, 70);
    doc.text(
      `Address: ${selectedUserData.address}`,
      10,
      70
    );
    doc.text(`Order Date: ${orderReceiptData.date_created_gmt}`, 10, 80);
    // Order Items Table
    const items = itemData.map((item) => [
      item.order_item_name, // Item Name
      item.order_item_id, // Reference No.
      `£${parseFloat(item.price.replace('£', '').trim()).toFixed(2)}`, // Price
    ]);
    if (items.length > 0) {
      doc.autoTable({
        startY: 100,
        head: [["Item Name", "Reference No.", "Amount"]],
        body: items,
      });
    }
    // Subtotal and Total
    const total = itemData.reduce(
      (sum, item) => sum + parseFloat(item.price.replace('£', '').trim()),
      0
    );
    doc.text(`Total: £${total.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 20);
    // Save the PDF
    doc.save("order_receipt.pdf");
  };

  render() {
    const { selectedUserData, orderReceiptData ,itemData } = this.state;
    return (
      <Fragment>
        <div className="page-title">
          <Row>
            <Col xs="12">
            <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/order-management/">Orders</Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/order-management/order-details/">Order Details</Link>
                </li>
                <li className='breadcrumb-item active'>Receipt</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm="12" xl="12" className="xl-100">
          <Card>
            <CardBody>
              <div className="invoice">
                <div>
                  <Row className="invo-header">
                    <Col sm="6">
                      <Media>
                        <div className="media-left">
                          <Image
                            attrImage={{
                              className: "media-object img-120",
                              src: logo,
                              alt: "",
                            }}
                          />
                          <br />
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <br />
                          <span>&nbsp;&nbsp;&nbsp;</span>
                        </div>
                      </Media>
                    </Col>
                    <Col sm="6">
                      <div className="text-md-end text-xs-center">
                        <H3>
                          {selectedUserData.name}{" "}
                         
                        </H3>
                        <P>
                          Receipt No.: #{orderReceiptData.id}
                          <br />
                          Payment Date:{" "}
                          <span className="digits">
                            {orderReceiptData.date_created_gmt}
                          </span>
                        </P>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="invo-profile">
                    <Col xl="4">
                      <div className="invo-profile-left">
                        <Media>
                          <Media body className="m-l-20">
                          <H4 attrH4={{ className: "media-heading f-w-600" }}>
  {itemData[0]?.book_user_owner_name || "Owner Name Not Available"}
</H4>
                          </Media>
                        </Media>
                      </div>
                    </Col>
                    <Col xl="8">
                      <div className="text-md-end">
                        <H6>Address</H6>
                        <P>
                          {selectedUserData.address}
                        </P>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive invoice-table" id="table">
                    <Table bordered striped>
                      <thead>
                        <tr>
                          <th className="item">
                            <H6 attrH6={{ className: "p-2 mb-0" }}>
                              Item Name
                            </H6>
                          </th>
                          <th className="quantity">
                            <H6 attrH6={{ className: "p-2 mb-0" }}>
                              Reference No.
                            </H6>
                          </th>
                          <th className="Rate">
                            <H6 attrH6={{ className: "p-2 mb-0" }}>Amount</H6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
      {/* Loop through the itemData array and display each item */}
      {itemData && itemData.length > 0 ? (
        itemData.map((item, index) => (
          <tr key={index}>
            <td>{item.order_item_name}</td> {/* Item Name */}
            <td>{item.order_item_id}</td>   {/* Reference No. */}
            <td>£{parseFloat(item.price.replace('£', '').trim()).toFixed(2)}</td> {/* Amount */}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="3" className="text-center">No items found</td>
        </tr>
      )}
    </tbody>
                    </Table>
                  </div>
                  <div
                    className="table-responsive invoice-table"
                    id="table"
                  ></div>
                  <Row className="mt-3">
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className="text-md-end">
                      <P>
    <b>Total:</b> £
    {Array.isArray(itemData) && itemData.length > 0
      ? itemData.reduce((sum, item) => sum + parseFloat(item.price.replace('£', '').trim()), 0).toFixed(2)
      : '0.00'}
  </P>

                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Col sm="12" className="text-center">
                <Button
                  color="primary"
                  className="me-2"
                  onClick={this.handleDownload}
                >
                  Download
                </Button>
                <Link
                 to="/order-management/order-details/">
                  <Btn attrBtn={{ color: "primary" }}>Cancel</Btn>
                </Link>
              </Col>
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  listOrderDetails: orderOperations.listOrderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReceipt);
