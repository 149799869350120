import React, { Fragment } from 'react';
import { Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { Container, Card, CardBody, CardHeader, CardFooter, Col, Nav, NavItem, NavLink, TabContent, TabPane, Input, Form, Row } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import excelImg from '../../assets/images/datatable/excel.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { orderOperations } from '../../state/ducks/order';
import viewIcon from '../../assets/images/datatable/list-details.png';
import { formatDateAndTime } from '../../utils/helper';
import '../../assets/scss/pagination.css';
import Pagination from '../Pagination';
import { Spinner } from 'react-bootstrap';
class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: '1',
      orderData: [],
      pendingData: [],
      processingData: [],
      shippedData: [],
      canceledData: [],
      failedData: [],
      successData: [],
      defaultSearchData: [],
      searchData: '',
      currentPage: 1,
      itemsPerPage: 10,
      isDisabledNext: true,
      sortedColumn: null,
      sortOrder: 'asc',
      status: "",
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      this.callListOrders();
    } catch (err) {
      console.log("componentDidMount ", err);
    }
  }
  
  generateExcelData = () => {
    const { defaultSearchData } = this.state;
    const xlsxData = [
      ['Reference No.', 'Name', 'Order Status', 'Email', 'Order Date', 'Note', 'Price', 'Item']
    ];
    for (const data of defaultSearchData) {
      const dataRow = [
        data.id,
        data.userName,
        data.status,
        data.email,
        formatDateAndTime(data.order_date),
        data.customer_note,
        data.total_amount,
        data.item
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, 'order.xlsx');
  };

  handleViewDetailsClick = (e) => {
    localStorage.setItem("selectedOrderId", e.target.getAttribute('data-test-id'));
    window.open("/order-management/order-details/", "_self");
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber,
      isDisabledNext: true },
      () =>{
        this.callListOrders();
      }
    );
  };
  handleTabClick = async (e) => {
    const basicTab = e.target.getAttribute("data-test-id");
    
    let status = '';
    // Determine the status based on the tab clicked
    if (parseInt(basicTab) === 2) {
      status = "wc-completed";
    } else if (parseInt(basicTab) === 3) {
      status = "wc-pending";
    } else if (parseInt(basicTab) === 4) {
      status = "wc-processing";
    } else if (parseInt(basicTab) === 5) {
      status = "wc-shipped";
    } else if (parseInt(basicTab) === 6) {
      status = "wc-cancelled";
    } else if (parseInt(basicTab) === 7) {
      status = "wc-failed";
    } else {
      status = "";  // Default to all orders
    }
  
    // Consolidate state updates into one setState call
    this.setState(
      {
        currentPage: 1,
        BasicTab: basicTab,
        status: status,
        searchData: '',
        defaultSearchData: [],
        isDisabledNext: true,
      },
      () => {
        this.callListOrders();
      }
    );
  };
  
  callListOrders = async () => {
    const {  itemsPerPage, currentPage, status } = this.state;
    this.setState({ loading: true });
    
    let body = {
      pageNo: currentPage,
      pageSize: itemsPerPage,
    };
  
    if (status) {
      body.status = status;
    }
  
    try {
      const response = await this.props.listOrder(body);
      const payload = response.payload;
      const isDisabledNext = payload.length !== 10;
  
      // Create a state update object
      const stateUpdate = {
        defaultSearchData: payload,
        isDisabledNext
      };
  
      // Add the appropriate data based on status
      switch(status) {
        case "wc-completed":
          stateUpdate.successData = payload;
          break;
        case "wc-pending":
          stateUpdate.pendingData = payload;
          break;
        case "wc-processing":
          stateUpdate.processingData = payload;
          break;
        case "wc-shipped":
          stateUpdate.shippedData = payload;
          break;
        case "wc-cancelled":
          stateUpdate.canceledData = payload;
          break;
        case "wc-failed":
          stateUpdate.failedData = payload;
          break;
        default:
          stateUpdate.orderData = payload;
      }
  
      this.setState(stateUpdate);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ loading: false });
    }
  };
  DefaultSearchChange = (e) => {
    const searchData = e.target.value.toLowerCase();
    const { status } = this.state;
    
    // Get the correct data source based on current status
    let sourceData;
    switch(status) {
      case "wc-completed":
        sourceData = this.state.successData;
        break;
      case "wc-pending":
        sourceData = this.state.pendingData;
        break;
      case "wc-processing":
        sourceData = this.state.processingData;
        break;
      case "wc-shipped":
        sourceData = this.state.shippedData;
        break;
      case "wc-cancelled":
        sourceData = this.state.canceledData;
        break;
      case "wc-failed":
        sourceData = this.state.failedData;
        break;
      default:
        sourceData = this.state.orderData;
    }
  
    // Filter the data
    const filteredData = sourceData.filter(data => {
      // Convert all values to string and filter out null values
      const valuesToSearch = Object.values(data)
        .filter(value => value !== null)
        .map(value => String(value).toLowerCase());
      
      // Check if any value includes the search term
      return valuesToSearch.some(value => value.includes(searchData));
    });
  
    // Update both the search term and filtered results
    this.setState({
      searchData: e.target.value,
      defaultSearchData: filteredData
    });
  };
  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const { BasicTab, isDisabledNext, currentPage, defaultSearchData,  sortedColumn, sortOrder, searchData } = this.state;
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Order Management</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Order Management</H5>
                  <img src={excelImg} width="35" height="35" alt='excel' onClick={this.handleDownload}></img>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='9'></Col>
                      <Col md='3'>
                        <div className="job-filter">
                          <div className="faq-form">
                            <Input type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                            <Search className="search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Nav tabs>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '1' ? 'active' : ''} data-test-id={1} onClick={this.handleTabClick}>
                        All Order
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '2' ? 'active' : ''} data-test-id={2} onClick={this.handleTabClick}>
                        Completed Order
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '3' ? 'active' : ''} data-test-id={3} onClick={this.handleTabClick}>
                        Pending Order
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '4' ? 'active' : ''} data-test-id={4} onClick={this.handleTabClick}>
                        Processing Order
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '5' ? 'active' : ''} data-test-id={5} onClick={this.handleTabClick} >
                        Shipping Order
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '6' ? 'active' : ''} data-test-id={6} onClick={this.handleTabClick}>
                        Cancelled Order
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '7' ? 'active' : ''} data-test-id={7}  onClick={this.handleTabClick}>
                        Failed Order
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={BasicTab}>
                    <TabPane className='fade show' tabId='1'>
                    {this.state.loading ? (
                        <div className="loading-spinner">
                         {/* You can use a library like react-spinners or any custom spinner */}
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Reference No. {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('userName')}>Name {sortedColumn === 'userName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Order Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('order_date')}>Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_note')}>Note {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Price {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('item')}>Item {sortedColumn === 'item' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {defaultSearchData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.userName}</td>
                              {data.status === 'User' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>{data.email}</td>
                              <td>{data.order_date}</td>
                              <td>{data.customer_note}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.item}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      )}  
                    </TabPane>
                    <TabPane tabId='2'>
                    {this.state.loading ? (
                        <div className="loading-spinner">
                          {/* You can use a library like react-spinners or any custom spinner */}
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Reference No. {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('userName')}>Name {sortedColumn === 'userName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Order Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('order_date')}>Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_note')}>Note {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Price {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('item')}>Item {sortedColumn === 'item' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {defaultSearchData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.userName}</td>
                              {data.status === 'wc-completed' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>{data.email}</td>
                              <td>{data.order_date}</td>
                              <td>{data.customer_note}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.item}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      )}  
                    </TabPane>
                    <TabPane tabId='3'>
                    {this.state.loading ? (
                      <div className="loading-spinner">
                        {/* You can use a library like react-spinners or any custom spinner */}
                        <Spinner animation="border" variant="primary" />
                     </div>
                    ) : (
                        <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Reference No. {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('userName')}>Name {sortedColumn === 'userName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Order Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('order_date')}>Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_note')}>Note {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Price {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('item')}>Item {sortedColumn === 'item' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {defaultSearchData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.userName}</td>
                              {data.status === 'wc-pending' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>{data.email}</td>
                              <td>{data.order_date}</td>
                              <td>{data.customer_note}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.item}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      )}
                    </TabPane>
                    <TabPane tabId='4'>
                    {this.state.loading ? (
                     <div className="loading-spinner">
                          {/* You can use a library like react-spinners or any custom spinner */}
                            <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Reference No. {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('userName')}>Name {sortedColumn === 'userName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Order Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('order_date')}>Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_note')}>Note {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Price {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('item')}>Item {sortedColumn === 'item' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {defaultSearchData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.userName}</td>
                              {/* <td><span className='badge badge-light-primary' style={{ background: '#ffffcc', color: '#808000' }}>{data.status}</span></td> */}

                              {data.status === 'wc-processing' ? <td><span className='badge badge-light-info'  style={{
                                  background: "#ffffcc",
                                  color: "#808000",
                                }}>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary' >{data.status}</span></td>}
                              <td>{data.email}</td>
                              <td>{data.order_date}</td>
                              <td>{data.customer_note}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.item}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      )}
                    </TabPane>
                    <TabPane tabId='5'>
                    {this.state.loading ? (
                      <div className="loading-spinner">
                          {/* You can use a library like react-spinners or any custom spinner */}
                        <Spinner animation="border" variant="primary" />
                       </div>
                      ) : (
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Reference No. {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('userName')}>Name {sortedColumn === 'userName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Order Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('order_date')}>Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_note')}>Note {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Price {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('item')}>Item {sortedColumn === 'item' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {defaultSearchData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.userName}</td>
                              {data.status === 'wc-shipped' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>{data.email}</td>
                              <td>{data.order_date}</td>
                              <td>{data.customer_note}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.item}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    )} 
                    </TabPane>
                    <TabPane tabId='6'>
                    {this.state.loading ? (
                      <div className="loading-spinner">
                            {/* You can use a library like react-spinners or any custom spinner */}
                         <Spinner animation="border" variant="primary" />
                      </div>
                      ) : (
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Reference No. {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('userName')}>Name {sortedColumn === 'userName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Order Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('order_date')}>Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_note')}>Note {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Price {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('item')}>Item {sortedColumn === 'item' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {defaultSearchData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.userName}</td>
                              {data.status === 'wc-cancelled' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>{data.email}</td>
                              <td>{data.order_date}</td>
                              <td>{data.customer_note}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.item}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                     )}
                    </TabPane>
                    <TabPane tabId='7'>
                    {this.state.loading ? (
                           <div className="loading-spinner">
                            {/* You can use a library like react-spinners or any custom spinner */}
                             <Spinner animation="border" variant="primary" />
                             </div>
                      ) : (
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Reference No. {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('userName')}>Name {sortedColumn === 'userName' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Order Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('email')}>Email {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('order_date')}>Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_note')}>Note {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_amount')}>Price {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('item')}>Item {sortedColumn === 'item' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {defaultSearchData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.userName}</td>
                              {data.status === 'wc-failed' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>}
                              <td>{data.email}</td>
                              <td>{data.order_date}</td>
                              <td>{data.customer_note}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.item}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
                <CardFooter>
                  <Pagination currentPage={currentPage} isDisabledNext={isDisabledNext} totalPages={""} onPageChange={this.handlePageChange} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  listOrder: orderOperations.listOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);