import * as types from "./types";
import * as API_URL from "../../../env";

export const listCustomer = (payload) => ({
    type: types.FETCH_CUSTOMER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/customer/list-customers",
        method: "GET",
        body: payload
    },
});

export const listUser = (payload) => ({
    type: types.FETCH_CUSTOMER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/list-users",
        method: "GET",
        body: payload
    },
});

export const getCustomersDetails = (payload) => ({
    type: types.FETCH_CUSTOMER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/customer/get-customers-details",
        method: "POST",
        body: payload
    },
});

export const updateNote = (note) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/customer/update-note",
        method: "PUT",
        body: note
    },
});