import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { H5, Btn } from "../../AbstractElements";
import { Row, Col, CardBody, CardFooter, CardHeader, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Save, Cancel } from '../../Constant';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { questionOperations } from '../../state/ducks/question';
import { masterOperations } from '../../state/ducks/master';
import { categoryOperations } from '../../state/ducks/category';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
class QuestionAdd extends React.Component {

  constructor() {
    super();
    this.state = {
      category: [],
      language: [],
      selectedCategories: [],
      value: 'write something to describe your product',
      categoryId: localStorage.getItem("selectedCategoryId"),
      selectedCategoryId: localStorage.getItem("selectedCategoryId")
    }
  }

  async componentDidMount() {
    try {
      this.loadLocalStorageData();
    } catch (err) {
      console.log(err);
    }
  }

  loadLocalStorageData = async () => {
    //Store category
    let category = localStorage.getItem('category');
    if (category) {
      category = JSON.parse(category);
      this.setState({ category });
    } else {
      let response = await this.props.listCategoryMaster();
      if (response.payload.length !== 0) {
        const searchString = JSON.stringify(response.payload);
        localStorage.setItem('category', searchString);
        category = JSON.parse(searchString);
        this.setState({ category });
      }
    }
    // Store Language
    let language = localStorage.getItem('language');
    if (language) {
      language = JSON.parse(language);
      this.setState({ language });
    } else {
      let response = await this.props.listLanguage();
      if (response.payload.length !== 0) {
        const languages = JSON.stringify(response.payload);
        localStorage.setItem('language', languages);
        language = JSON.parse(languages);
        this.setState({ language });
      }
    }
  }

  handle1Change = (value) => {
    this.setState({ value });
  };

   // Handle category selection
   handleCategoryChange = (selectedOptions) => {
    const selectedCategories = selectedOptions ? selectedOptions.map(option => option.value) : [];
    this.setState({ selectedCategories });
  };

  onInsert = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        question: this.state.questionTitle,
        status: this.state.statusId,
        category_ids: this.state.selectedCategories,
        question_language: this.state.languageName,
        question_description: this.state.value,
        question_notes: this.state.questionNotes
      }
      const response = await this.props.addQuestion(payload);
      toast.success(response.message, { autoClose: 10000 });
      if (localStorage.getItem("selectedCategoryId")) {
        window.open("/question-category/category-details/", "_self");
      }
      else {
        window.open("/question-category/question/", "_self");
      }
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  handleChange = async (e) => {
    try {
      let tid = e.target.id;
      switch (tid) {
        case 'category':
          this.setState({ categoryId: e.target.value });
          break;
        case 'status':
          this.setState({ statusId: e.target.value });
          break;
        case 'language':
          this.setState({ languageName: e.target.value });
          break;
        default:
          console.log("No Matching target found");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { category, language, categoryId, selectedCategoryId, selectedCategories  } = this.state;

    const categoryOptions = category.map(data => ({
      value: data.id,
      label: data.category_name
    }));
    return (
      <Fragment>
        <ToastContainer />
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/question-category/question/">Question Category</Link>
                </li>
                <li className='breadcrumb-item active'>Add Question</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <div className='edit-profile'>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className='d-flex align-items-center justify-content-between'>
                    <H5>Add Question</H5>
                  </CardHeader>
                  <Form onSubmit={this.onInsert}>
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">Add Question</Label>
                            <Input type="text" placeholder="Enter Question" onChange={(e) => this.setState({ questionTitle: e.target.value })} />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                        <FormGroup>
                            <Label className="form-label">Associated Category</Label>
                            <Select
                              isMulti
                              name="category"
                              options={categoryOptions}
                              onChange={this.handleCategoryChange} 
                              value={categoryOptions.filter(option => selectedCategories.includes(option.value))}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label className="form-label">Question Language</Label>
                            <Input type="select" name="language" id="language" onChange={this.handleChange}>
                              <option value=''>Select Language</option>
                              {language.map((data, i) =>
                                <option key={i} value={data.language_name}>{data.language_name}</option>
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label className="form-label">Question Description</Label>
                            <ReactQuill
                              value={this.state.value}
                              onChange={this.handle1Change}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label className="form-label">Question Notes(Internal)</Label>
                            <Input type="textarea" placeholder="Enter Question Notes" onChange={(e) => this.setState({ questionNotes: e.target.value })} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Btn attrBtn={{ color: "primary", type: "submit" }} >{Save}</Btn>&nbsp;&nbsp;&nbsp;
                      <Link to="/question-category/question/">
                        <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                      </Link>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment >
    )
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  addQuestion: questionOperations.addQuestion,
  listCategory: categoryOperations.listCategory,
  listCategoryMaster: categoryOperations.listCategoryMaster,
  listLanguage: masterOperations.listLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAdd)