import React, { Fragment } from 'react';
import { Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { Container, Card, CardBody, CardHeader, CardFooter, Col, Nav, NavItem, NavLink, TabContent, TabPane, Input, Form, Row } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import excelImg from '../../assets/images/datatable/excel.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { printBookOrderOperations } from '../../state/ducks/printBookOrder';
import viewIcon from '../../assets/images/datatable/list-details.png';
import { formatDateAndTime } from '../../utils/helper';
import '../../assets/scss/pagination.css';
import Pagination from '../Pagination';

class PrintBookList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: '1',
      printBookOrderData: [],
      pendingData: [],
      processingData: [],
      shippedData: [],
      canceledData: [],
      successData: [],
      defaultSearchData: [],
      searchData: '',
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: 'asc'
    };
  }
  async componentDidMount() {
    try {
      // get all order
      const response = await this.props.listPrintBookOrder();
      this.setState({ 
        printBookOrderData: response.payload.combinedOrders, 
        defaultSearchData: response.payload.combinedOrders
       });
    }catch(err){
        console.log(err)
    }
}

  generateExcelData = () => {
    const { defaultSearchData } = this.state;
    const xlsxData = [
      ["Order ID", "Customer Name", "Print Order Date", "Included Copy", "Extra Copy", "Total Billed Amount", "Status"]
    ];
    for (const data of defaultSearchData) {
      const dataRow = [
        data.id,
        data.customer_name,
        formatDateAndTime(data.created_date),
        data.included_copy,
        data.extra_copy,
        data.total_billed_amount,
        data.status,
        
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  handleDownload = () => {
      const workbook = this.generateExcelData();
      XLSX.writeFile(workbook, "Print_Book_List.xlsx");
    };
  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    const defaultSearchData = this.state.printBookOrderData.filter(data => {
      const valuesToSearch = Object.values(data).filter(value => value !== null);
      return valuesToSearch.some(value =>
        String(value).toLowerCase().includes(searchData.toLowerCase())
      );
    });
    this.setState({ searchData, defaultSearchData });
  };

  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleViewDetailsClick = (e) => {
    localStorage.setItem("selectedOrderId", e.target.getAttribute('data-test-id'));
    window.open("/print-book-order/print-book-details/", "_self");
  }

  render() {
    const { BasicTab, pendingData, processingData, shippedData, canceledData, successData, defaultSearchData, currentPage, itemsPerPage, sortedColumn, sortOrder, searchData } = this.state;
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData ? defaultSearchData.slice(indexOfFirstItem, indexOfLastItem) : [];
    const totalPages = defaultSearchData ? Math.ceil(defaultSearchData.length / itemsPerPage) : 1;
    
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Printing Book Order</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Printing Book Order</H5>
                  <img src={excelImg} width="35" height="35" alt='excel' onClick={this.handleDownload}></img>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='9'></Col>
                      <Col md='3'>
                        <div className="job-filter">
                          <div className="faq-form">
                            <Input type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                            <Search className="search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
            
                  <TabContent activeTab={BasicTab}>
                    <TabPane className='fade show' tabId='1'>
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('id')}>Order ID {sortedColumn === 'id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('customer_Name')}>Customer Name {sortedColumn === 'customer_Name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('print_order_date')}>Print Order Date {sortedColumn === 'order_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('included_copy')}>Included Copy {sortedColumn === 'total_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('Extra_copy')}>Extra Copy {sortedColumn === 'email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('total_billed_amount')}>Total Billed Amount {sortedColumn === 'customer_note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('status')}>Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('Action')}>Action {sortedColumn === 'Action' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            {/* <th>View Details</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.id}</td>
                              <td>{data.customer_name} </td>
                              <td>{data.created_date}</td>
                              <td>{data.included_copy}</td>
                              <td>{data.extra_copy}</td>
                              <td>£{data.total_billed_amount}</td>
                              <td>{data.status}</td>
                               {/* {data.status === 'User' ? <td><span className='badge badge-light-info'>{data.status}</span></td>
                                : <td><span className='badge badge-light-primary'>{data.status}</span></td>} */}
                              {/* <td>{data.item}</td> */}
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                    
                  </TabContent>
                </CardBody>
                <CardFooter>
                  <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    listPrintBookOrder : printBookOrderOperations.listPrintBookOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintBookList);