import * as types from "./types";
import * as API_URL from "../../../env";

export const listDiscount = (payload) => ({
    type: types.FETCH_DISCOUNT_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/discount/list-discount-code",
        method: "GET",
        body: payload
    },
});

export const addDiscount = (payload) => ({
    type: types.INSERT_DISCOUNT,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/discount/add-discount-code",
        method: "POST",
        body: payload
    },
});

export const updateDiscount = (discount) => ({
    type: types.UPDATE_DISCOUNT,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/discount/update-discount-code",
        method: "PUT",
        body: discount
    },
});

export const updateDiscountStatus = (discount) => ({
    type: types.UPDATE_DISCOUNT_STATUS,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/discount/update-discount-code-status",
        method: "PUT",
        body: discount
    },
});

export const deleteDiscount = (payload) => ({
    type: types.DELETE_DISCOUNT,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/discount/delete-discount-code",
        method: "DELETE",
        body: payload
    },
});