import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { H5, H4, Btn } from "../../AbstractElements";
import excelImg from "../../assets/images/datatable/excel.png";
import userCircle from "../../assets/images/datatable/user-circle.png";
import list from "../../assets/images/datatable/list.png";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import { formatDateAndTime } from "../../utils/helper";
import Pagination from "../Pagination";
import "../../assets/scss/pagination.css";
import receipt from "../../assets/images/datatable/receipt.png";
import icon from "../../assets/images/datatable/icon.png";
import { masterOperations } from "../../state/ducks/master";
import { orderOperations } from "../../state/ducks/order";
import { customerOperations } from "../../state/ducks/customer";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import jsPDF from "jspdf";
import logo from "../../assets/images/logo/logo.png";
class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: 'all',
      selectedUserData: {},
      selectedBuyerData: {},
      orderItemsData: [],
      itemData: [],
      successItemsData: [],
      successOrderItem: [],
      pendingItemsData: [],
      processingItemsData: [],
      shippedItemsData: [],
      canceledItemsData: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: "asc",
      modalOpen: false,
      modalNoteOpen: false,
      filteredData: [],
    };
  }

  async componentDidMount() {
    // for all orders items
    const orderId = localStorage.getItem("selectedOrderId");
    const aid = parseFloat(orderId);
    const response = await this.props.listOrderDetails({ orderId: aid });
    this.setState({
      BasicTab: 'all',
      selectedUserData: response.payload.user_details,
      selectedBuyerData: response.payload.buyer_details,
      itemData: response.payload.items,
      orderItemsData: response.payload.order_items,
      filteredData: response.payload.order_items,
    });
  }

  filterDataByStatus(status) {
    const { orderItemsData } = this.state;
    // Log the status value for debugging
    const filtered =
      status === 'all'
        ? orderItemsData
        : orderItemsData.filter((item) => {
            return item.status === status; // Ensure exact match with status
          });
    this.setState({ filteredData: filtered });
  }

  handleTabClick(status) {
    this.setState(
      {
        BasicTab: status, // Update BasicTab based on status
      },
      () => {
        this.filterDataByStatus(status); // Call filtering after state update
      }
    );
  }
  handleGeneratePDF = () => {
    const orderReceiptData = JSON.parse(localStorage.getItem("orderReceipt"));
    const { selectedUserData, itemData } = this.state;
    const doc = new jsPDF();
    // Add Logo
    doc.addImage(logo, "PNG", 10, 30, 60, 20);
    // Add Title
    doc.setFontSize(18);
    doc.text("Order Receipt", 80, 20);
    // Customer Info
    doc.setFontSize(12);
    doc.text(`Customer Name: ${selectedUserData.name}`, 10, 60);
    doc.text(`Address: ${selectedUserData.address}`, 10, 70);
    doc.text(`Order Date: ${orderReceiptData.date_created_gmt}`, 10, 80);
    // Order Items Table
    const items = itemData.map((item) => [
      item.order_item_name, // Item Name
      item.order_item_id, // Reference No.
      `£${parseFloat(item.price.replace("£", "").trim()).toFixed(2)}`, // Price
    ]);
    if (items.length > 0) {
      doc.autoTable({
        startY: 100,
        head: [["Item Name", "Reference No.", "Amount"]],
        body: items,
      });
    }
    // Subtotal and Total
    const total = itemData.reduce(
      (sum, item) => sum + parseFloat(item.price.replace("£", "").trim()),
      0
    );
    doc.text(`Total: £${total.toFixed(2)}`, 10, doc.lastAutoTable.finalY + 20);
    // Generate PDF as base64
    const pdfBase64 = doc.output("datauristring"); // Get PDF as base64 encoded string
    return pdfBase64; // Return base64 PDF to send via email
  };

  sendEmailWithPDF = async (e) => {
    e.preventDefault();
    try {
      const { selectedUserData } = this.state;
      const pdfBase64 = this.handleGeneratePDF();
      const response = await this.props.sendEmail({
        to: selectedUserData.user_email,
        attachment: pdfBase64,
        subject: "Order Details",
        name: selectedUserData.name,
      });
      if (response.status === 200) {
        this.setState((prevState) => ({
          modalOpen: false,
        }));
      }
      toast.success("Email send successfully.", { autoClose: 10000 });
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  generateExcelData = () => {
    const { orderItemsData } = this.state;
    let purchaseOrderItemsData;
    if (Array.isArray(orderItemsData) && orderItemsData !== null) {
      purchaseOrderItemsData = orderItemsData.length > 0 ? orderItemsData : [];
    } else {
      purchaseOrderItemsData = [];
    }
    // Header for the Excel sheet
    const xlsxData = [
      [
        "Item Name",
        "Reference No.",
        "Amount",
        "Status",
        "Date Time",
        "IP Address",
        "Payment Method",
      ],
    ];
    // Loop through the orderItemsData (main order object)
    for (const orderData of purchaseOrderItemsData) {
      // Access the order items array, but first ensure it is an array
      const orderItems = orderData.orderItems;
      // Check if order_items is actually an array
      if (Array.isArray(orderItems)) {
        const totalAmount = parseFloat(orderData.total_amount); // Order level total amount
        // Loop through each order item inside orderItems array
        for (const item of orderItems) {
          // Prepare a row for each item
          const dataRow = [
            item.order_item_name, // Item Name
            orderData.id, // Order ID (Reference No.)
            `£${totalAmount.toFixed(2)}`, // Amount formatted as currency (use total amount here)
            orderData.status, // Order status (wc-processing)
            formatDateAndTime(orderData.date_created_gmt), // Date formatted
            orderData.ip_address, // IP Address
            orderData.payment_method, // Payment Method
          ];
          // Push the row data into the xlsxData array
          xlsxData.push(dataRow);
        }
      } else {
        // If order_items is not an array, log the error and skip this order
        console.error("order_items is not an array", orderData.order_items);
      }
    }
    // Convert xlsxData into an Excel sheet
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, "order_details.xlsx");
  };

  handleOrderReceiptClick = (data) => {
    localStorage.setItem("orderReceipt", JSON.stringify(data));
    window.open("/order-management/order-details/order-receipt/", "_self");
  };

  handleShowDetails = (data) => {
    localStorage.setItem("orderReceipt", JSON.stringify(data));
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  onCloseModal = () => {
    this.setState((prevState) => ({
      modalOpen: false,
    }));
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleTableSort = (column) => {
    const { orderItemsData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder =
      column === sortedColumn && sortOrder === "asc" ? "desc" : "asc";

    // Sort the data based on the selected column and sortOrder
    const sortedData = orderItemsData.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      orderItemsData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  updateNotes = async (e) => {
    e.preventDefault();
    try {
      const orderId = localStorage.getItem("selectedOrderId");
      const aid = parseFloat(orderId);
      const { selectedUserData } = this.state;
      const response = await this.props.updateNote({
        userId: selectedUserData.id,
        id: aid,
        note: selectedUserData.note,
      });
      if (response.status === 200) {
        this.setState((prevState) => ({
          modalNoteOpen: false,
        }));
      }
      toast.success("Notes updated successfully.", { autoClose: 10000 });
      window.open("/order-management/order-details/", "_self");
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  handleInput = async (e) => {
    try {
      let tid = e.target.id;
      const { selectedUserData } = this.state;
      switch (tid) {
        case "note":
          const note = e.target.value;
          this.setState({
            selectedUserData: {
              ...selectedUserData,
              note,
            },
          });
          break;
        default:
          console.log("No Matching target found in handleInput");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleShowNotes = () => {
    this.setState((prevState) => ({
      modalNoteOpen: !prevState.modalNoteOpen,
    }));
  };

  onCloseNotesModal = () => {
    this.setState((prevState) => ({
      modalNoteOpen: false,
    }));
  };

  render() {
    const {
      BasicTab,
      modalOpen,
      modalNoteOpen,
      selectedUserData,
      selectedBuyerData,
      itemData,
      currentPage,
      itemsPerPage,
      sortedColumn,
      sortOrder,
      filteredData,
    } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let currentData;
    if (Array.isArray(filteredData) && filteredData !== null) {
      currentData =
      filteredData.length > 0
          ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
          : [];
    } else {
      currentData = [];
    }
    const totalPages =
      Array.isArray(filteredData) && filteredData !== null
        ? Math.ceil(filteredData.length / itemsPerPage)
        : 0;
    return (
      <Fragment>
        <ToastContainer />
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link style={{ color: "#000" }} to="/order-management/">
                    Order Management
                  </Link>
                </li>
                <li className="breadcrumb-item active">Order Details</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm="12" xl="12" className="xl-100">
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <H4>Order Details</H4>
              <img
                src={excelImg}
                width="35"
                height="35"
                alt="excel"
                onClick={this.handleDownload}
              ></img>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={userCircle}
                      width="22"
                      height="22"
                      alt="excel"
                      style={{ marginRight: "10px" }}
                    />
                    <H5 style={{ margin: 0 }}>Buyer Details</H5>
                  </Col>
                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={userCircle}
                      width="22"
                      height="22"
                      alt="excel"
                      style={{ marginRight: "10px" }}
                    />
                    <H5 style={{ margin: 0 }}>Owner Details</H5>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#595959",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">Name</div>
                      <div className="mb-2">Email</div>
                      <div className="mb-2">Address</div>
                      <div className="mb-2">Total Payment</div>
                      <div className="mb-2">Book User</div>
                      <div className="mb-2">Language</div>
                      <div className="mb-2">Notes</div>
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">{selectedUserData.name || ""}</div>
                      <div className="mb-2">
                        {selectedUserData.user_email || ""}
                      </div>
                      <div className="mb-2">
                        {selectedUserData.address || ""}
                      </div>
                      <div className="mb-2">
                        {selectedUserData.total_payment || ""}
                      </div>
                      <div className="mb-2">
                        {selectedUserData.user_status || ""}
                      </div>
                      <div className="mb-2">
                        {selectedUserData.language_id || ""}
                      </div>
                      <div className="mb-4">
                        <Input
                          type="textarea"
                          placeholder="Notes"
                          value={selectedUserData.note || ""}
                          onClick={this.handleShowNotes}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#595959",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">Name</div>
                      <div className="mb-2">Email</div>
                      <div className="mb-2">Address</div>
                      <div className="mb-2">Total Payment</div>
                      <div className="mb-2">Book User</div>
                      <div className="mb-2">Language</div>
                      <div className="mb-4">Notes</div>
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">{selectedBuyerData.name || ""}</div>
                      <div className="mb-2">
                        {selectedBuyerData.user_email || ""}
                      </div>
                      <div className="mb-2">
                        {selectedBuyerData.address || ""}
                      </div>
                      <div className="mb-2">
                        {selectedBuyerData.total_payment || ""}
                      </div>
                      <div className="mb-2">
                        {selectedBuyerData.buyer_status || ""}
                      </div>
                      <div className="mb-2">
                        {selectedBuyerData.language_id || ""}
                      </div>
                      <div className="mb-2">
                        <Input
                          type="textarea"
                          placeholder="Notes"
                          id="notes"
                          name="notes"
                          value={selectedBuyerData.note || ""}
                          onClick={this.handleShowNotes}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Modal
                  isOpen={modalNoteOpen}
                  toggle={() => this.handleShowNotes()}
                  style={{ marginTop: "200px" }}
                >
                  <Form onSubmit={this.updateNotes}>
                    <span
                      style={{ textAlign: "right", padding: "5px" }}
                      onClick={this.onCloseNotesModal}
                    >
                      Close
                    </span>
                    <ModalBody>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "20px",
                          padding: "7px",
                          background: "#890077",
                          color: "#fff",
                          height: "30px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      >
                        Update Notes
                      </div>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="8">
                          <Label>Notes</Label>
                          <Input
                            style={{ fontSize: "10px", padding: "11px" }}
                            type="textarea"
                            id="note"
                            name="note"
                            value={selectedUserData.note || ""}
                            onChange={this.handleInput}
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Col md="4"></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Col>
                        <Btn attrBtn={{ color: "primary", type: "submit" }}>
                          Update
                        </Btn>
                        &nbsp;&nbsp;
                      </Col>
                    </ModalFooter>
                  </Form>
                </Modal>
              </Form>
              <Row>
                <Col md="12" style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={list}
                    width="22"
                    height="22"
                    alt="excel"
                    style={{ marginRight: "10px" }}
                  />
                  <H5 style={{ margin: 0 }}>Order Items</H5>
                </Col>
              </Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "all" ? "active" : ""}
                    onClick={() => this.handleTabClick("all")}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "wc-completed" ? "active" : ""}
                    onClick={() => this.handleTabClick("wc-completed")}
                  >
                    Completed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "wc-pending" ? "active" : ""}
                    onClick={() => this.handleTabClick("wc-pending")} // Filter for Pending
                  >
                    Pending
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "wc-processing" ? "active" : ""}
                    onClick={() => this.handleTabClick("wc-processing")} // Filter for Processing
                  >
                    Processing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "wc-shipping" ? "active" : ""}
                    onClick={() => this.handleTabClick("wc-shipping")} // Filter for Shipping
                  >
                    Shipping
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "wc-cancelled" ? "active" : ""}
                    onClick={() => this.handleTabClick("wc-cancelled")} // Filter for Cancelled
                  >
                    Cancelled
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={BasicTab}>
                <TabPane className="fade show" tabId="all">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          Items{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_buyer_name")
                          }
                        >
                          Buyer Name{" "}
                          {sortedColumn === "book_user_buyer_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_owner_name")
                          }
                        >
                          Owner Name{" "}
                          {sortedColumn === "book_user_owner_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Date Time{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("ip_address")}>
                          IP Address{" "}
                          {sortedColumn === "ip_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("payment_method")}
                        >
                          Payment Method{" "}
                          {sortedColumn === "payment_method" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={() =>
                                  this.handleOrderReceiptClick(data)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={() => this.handleShowDetails(data)}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="wc-completed">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          Items{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_buyer_name")
                          }
                        >
                          Buyer Name{" "}
                          {sortedColumn === "book_user_buyer_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_owner_name")
                          }
                        >
                          Owner Name{" "}
                          {sortedColumn === "book_user_owner_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Date Time{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("ip_address")}>
                          IP Address{" "}
                          {sortedColumn === "ip_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("payment_method")}
                        >
                          Payment Method{" "}
                          {sortedColumn === "payment_method" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            <td>
                              <span
                                className="badge badge-light-primary"
                                style={{
                                  background: "#b3ffb3",
                                  color: "#00b300",
                                }}
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={() =>
                                  this.handleOrderReceiptClick(data)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={() => this.handleShowDetails(data)}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="wc-pending">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          Items{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_buyer_name")
                          }
                        >
                          Buyer Name{" "}
                          {sortedColumn === "book_user_buyer_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_owner_name")
                          }
                        >
                          Owner Name{" "}
                          {sortedColumn === "book_user_owner_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Date Time{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("ip_address")}>
                          IP Address{" "}
                          {sortedColumn === "ip_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("payment_method")}
                        >
                          Payment Method{" "}
                          {sortedColumn === "payment_method" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            {data.status === "wc-pending" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={() =>
                                  this.handleOrderReceiptClick(data)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={() => this.handleShowDetails(data)}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="wc-processing">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          Items{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_buyer_name")
                          }
                        >
                          Buyer Name{" "}
                          {sortedColumn === "book_user_buyer_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_owner_name")
                          }
                        >
                          Owner Name{" "}
                          {sortedColumn === "book_user_owner_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Date Time{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("ip_address")}>
                          IP Address{" "}
                          {sortedColumn === "ip_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("payment_method")}
                        >
                          Payment Method{" "}
                          {sortedColumn === "payment_method" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            <td>
                              <span
                                className="badge badge-light-primary"
                                style={{
                                  background: "#ffffcc",
                                  color: "#808000",
                                }}
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={() =>
                                  this.handleOrderReceiptClick(data)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={() => this.handleShowDetails(data)}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="wc-shipping">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          Items{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_buyer_name")
                          }
                        >
                          Buyer Name{" "}
                          {sortedColumn === "book_user_buyer_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_owner_name")
                          }
                        >
                          Owner Name{" "}
                          {sortedColumn === "book_user_owner_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Date Time{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("ip_address")}>
                          IP Address{" "}
                          {sortedColumn === "ip_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("payment_method")}
                        >
                          Payment Method{" "}
                          {sortedColumn === "payment_method" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            <td>
                              <span
                                className="badge badge-light-primary"
                                style={{
                                  background: "#ffffcc",
                                  color: "#808000",
                                }}
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={() =>
                                  this.handleOrderReceiptClick(data)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={() => this.handleShowDetails(data)}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="wc-cancelled">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          Items{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_buyer_name")
                          }
                        >
                          Buyer Name{" "}
                          {sortedColumn === "book_user_buyer_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("book_user_owner_name")
                          }
                        >
                          Owner Name{" "}
                          {sortedColumn === "book_user_owner_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Date Time{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("ip_address")}>
                          IP Address{" "}
                          {sortedColumn === "ip_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("payment_method")}
                        >
                          Payment Method{" "}
                          {sortedColumn === "payment_method" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            {data.status === "wc-canceled" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={() =>
                                  this.handleOrderReceiptClick(data)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={() => this.handleShowDetails(data)}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
              <Modal
                isOpen={modalOpen}
                toggle={() => this.handleShowDetails()}
                style={{ marginTop: "200px" }}
              >
                <Form onSubmit={this.sendEmailWithPDF}>
                  <span
                    style={{ textAlign: "right", padding: "5px" }}
                    onClick={this.onCloseModal}
                  >
                    Close
                  </span>
                  <ModalBody>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "20px",
                        padding: "7px",
                        background: "#890077",
                        color: "#fff",
                        height: "30px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      Send Email
                    </div>
                    <Row>
                      <Col md="2"></Col>
                      <Col md="8">
                        <Label>Email</Label>
                        <Input
                          style={{ fontSize: "10px", padding: "11px" }}
                          type="text"
                          value={selectedUserData.user_email}
                          disabled
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Col md="4"></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Col>
                      <Btn attrBtn={{ color: "primary", type: "submit" }}>
                        Send
                      </Btn>
                      &nbsp;&nbsp;
                    </Col>
                  </ModalFooter>
                </Form>
              </Modal>
            </CardBody>
            <CardFooter>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={this.handlePageChange}
              />
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  listOrderDetails: orderOperations.listOrderDetails,
  updateNote: customerOperations.updateNote,
  sendEmail: masterOperations.sendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
