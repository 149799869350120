import React, { Fragment } from "react";
import { Search } from "react-feather";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Form,
  Row,
} from "reactstrap";
import { Btn, H5 } from "../../AbstractElements";
import excelImg from "../../assets/images/datatable/excel.png";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import { questionOperations } from "../../state/ducks/question";
import viewIcon from "../../assets/images/datatable/list-details.png";
import editIcon from "../../assets/images/datatable/written-paper.png";
import { formatDateAndTime } from "../../utils/helper";
import "../../assets/scss/pagination.css";
import Pagination from "../Pagination";
import deleteIcon from "../../assets/images/datatable/waste-bin.png";
import reactiveIcon from "../../assets/images/datatable/waste-bin-r.png";
import { toast } from "react-toastify";

class QuestionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: "1",
      allQuestionData: [],
      activeQuestionData: [],
      inActiveQuestionData: [],
      defaultSearchData: [],
      category: [],
      searchData: "",
      currentPage: 1,
      itemsPerPage: 10,
      isDisabledNext: true,
      sortedColumn: null,
      sortOrder: "asc",
      isOn: false,
      status: "",
    };
  }

  async componentDidMount() {
    try {
      localStorage.removeItem("selectedCategoryId");
      let category = localStorage.getItem("category");
      if (category) {
        category = JSON.parse(category);
        this.setState({ category });
      }
      this.callListQuestion();
    } catch (err) {
      console.log(err);
    }
  }

  generateExcelData = () => {
    const { defaultSearchData } = this.state;
    const xlsxData = [["Question", "Category Name", "Last Edit", "Status"]];
    for (const data of defaultSearchData) {
      const dataRow = [
        data.name,
        data.category,
        formatDateAndTime(data.last_edit),
        data.status,
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, "question.xlsx");
  };

  handleEditClick = (e) => {
    localStorage.setItem(
      "selectedQuestionId",
      e.target.getAttribute("data-test-id")
    );
    localStorage.setItem("isViewMode", "false");
    window.open("/question-category/question-edit/", "_self");
  };

  onUpdate = async (e) => {
    e.preventDefault();
    try {
      const { allQuestionData } = this.state;
      const questionId = Number(e.target.getAttribute("data-test-id"));
      const question = allQuestionData.find((cat) => cat.id === questionId);
      let newStatus = question.status === "Active" ? "Inactive" : "Active";
      let updateResponse = await this.props.updateQuestionStatus({
        id: questionId,
        status: newStatus === "Active" ? 1 : 0,
      });

      // Update the local state with the new status
      this.setState({
        allQuestionData: allQuestionData.map(
          (cat) => (cat.id === questionId ? { ...cat, status: newStatus } : cat) // Update status of the clicked category
        ),
      });

      toast.success(updateResponse.message, { autoClose: 10000 });
      window.open("/question-category/question/", "_self");
    } catch (err) {
      toast.error(err.response ? err.response.message : "Update failed", {
        autoClose: 10000,
      });
      console.log(err);
    }
  };

  handleQuestionDetailsClick = (e) => {
    localStorage.setItem(
      "selectedQuestionId",
      e.target.getAttribute("data-test-id")
    );
    localStorage.setItem("isViewMode", "true");
    // localStorage.setItem("isViewMode", "false");
    localStorage.setItem("viewMode", "View");
    window.open("/question-category/question-edit/", "_self");
  };

  processQuestionData = (data) => {
    return data.map((question) => {
      let categoryString = "";
      if (question.category && question.category.length > 0) {
        try {
          const categoryNamesList = question.category
            .map((id) => {
              const category = this.state.category.find((cat) => cat.id === id);
              return category ? category.category_name : "Unknown Category";
            })
            .join(", ");
          categoryString = categoryNamesList;
        } catch (error) {
          console.error("Error parsing categories:", error);
        }
      } else {
        categoryString = "No Categories";
      }
      return { ...question, category: categoryString };
    });
  };

  handlePageChange = async (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber,
        isDisabledNext: true,
      },
      () => {
        this.callListQuestion();
      }
    );
  };

  handleTabClick = async (e) => {
    const basicTab = e.target.getAttribute("data-test-id");
    if (parseInt(basicTab) === 2) {
      this.setState(
        {
          currentPage: 1,
          BasicTab: basicTab,
          status: 1,
          isDisabledNext: true,
        },
        () => {
          this.callListQuestion();
        }
      );
    } else if (parseInt(basicTab) === 3) {
      this.setState(
        {
          currentPage: 1,
          BasicTab: basicTab,
          status: 0,
          isDisabledNext: true,
        },
        () => {
          this.callListQuestion();
        }
      );
    } else {
      this.setState(
        {
          currentPage: 1,
          BasicTab: basicTab,
          status: 2,
          isDisabledNext: true,
        },
        () => {
          this.callListQuestion();
        }
      );
    }
  };

  callListQuestion = async () => {
    const { searchData, itemsPerPage, currentPage, status } = this.state;
    let body = {
      search: searchData,
      pageNo: currentPage,
      pageSize: itemsPerPage,
    };
    if (status === 0 || status === 1) {
      body.status = status;
    }
    const response = await this.props.listQuestion(body);
    const processedData = this.processQuestionData(response.payload);
    if (status === 1) {
      this.setState({
        activeQuestionData: processedData,
        defaultSearchData: processedData,
        isDisabledNext: processedData.length !== 10,
      });
    } else if (status === 0) {
      this.setState({
        inActiveQuestionData: processedData,
        defaultSearchData: processedData,
        isDisabledNext: processedData.length !== 10,
      });
    } else {
      this.setState({
        allQuestionData: processedData,
        defaultSearchData: processedData,
        isDisabledNext: processedData.length !== 10,
      });
    }
  };

  turnOn = () => {
    this.setState({
      isOn: true, // Set isOn to true
    });
  };

  turnOff = () => {
    this.setState({
      isOn: false, // Set isOn to false
    });
  };

  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    this.setState({ searchData, currentPage: 1 }, () => {
      this.callListQuestion();
    });
  };

 
  render() {
    const {
      BasicTab,
      defaultSearchData,
      activeQuestionData,
      inActiveQuestionData,
      isDisabledNext,
      currentPage,
      itemsPerPage,
      sortedColumn,
      sortOrder,
      searchData,
    } = this.state;
    const turnOnButtonStyle = {
      backgroundColor: this.state.isOn ? "#890077" : "gray",
      color: "white",
      padding: "7px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      marginRight: "10px",
      fontSize: "12px",
    };
    const turnOffButtonStyle = {
      backgroundColor: this.state.isOn ? "gray" : "#890077",
      color: "white",
      padding: "7px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "12px",
    };
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData; //.slice(indexOfFirstItem, indexOfLastItem);
    // const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
    return (
      <Fragment>
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">Question Category</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <H5>Question Category Management</H5>
                  <div className="d-flex align-items-center gap-3">
                    <img
                      src={excelImg}
                      width="35"
                      height="35"
                      alt="excel"
                      onClick={this.handleDownload}
                    ></img>
                    <Link to="/question-category/question-add/">
                      <Btn
                        attrBtn={{ color: "primary d-flex align-items-center" }}
                      >
                        Add Question
                      </Btn>
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="9" className="mb-2">
                        <Link to="/question-category/">
                          <button
                            style={turnOnButtonStyle}
                            onClick={this.turnOn}
                            type="button"
                          >
                            Category
                          </button>
                        </Link>
                        <Link to="/question-category/question/">
                          <button
                            style={turnOffButtonStyle}
                            onClick={this.turnOff}
                            type="button"
                          >
                            Question
                          </button>
                        </Link>
                      </Col>
                      <Col md="3">
                        <div className="job-filter">
                          <div className="faq-form">
                            <Input
                              type="text"
                              placeholder="Search"
                              value={searchData}
                              onChange={this.DefaultSearchChange}
                            />
                            <Search className="search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        href="#javascript"
                        className={BasicTab === "1" ? "active" : ""}
                        data-test-id={1}
                        onClick={this.handleTabClick}
                      >
                        All Question
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#javascript"
                        className={BasicTab === "2" ? "active" : ""}
                        data-test-id={2}
                        onClick={this.handleTabClick}
                      >
                        Active
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#javascript"
                        className={BasicTab === "3" ? "active" : ""}
                        data-test-id={3}
                        onClick={this.handleTabClick}
                      >
                        Inactive
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={BasicTab}>
                    <TabPane className="fade show" tabId="1">
                      <Table
                        ref="tbl"
                        striped
                        hover
                        responsive
                        bordered
                        id="data-table-zero"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Question </th>
                            <th>Category Name </th>
                            <th>Language </th>
                            <th>Last Edit </th>
                            <th>Status </th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) => (
                            <tr key={i}>
                              <td>{indexOfFirstItem + i + 1}</td>
                              <td>{data.name}</td>
                              <td>{data.category}</td>
                              <td>{data.language}</td>
                              <td>{formatDateAndTime(data.last_edit)}</td>
                              {data.status === "Active" ? (
                                <td>
                                  <span className="badge badge-light-info">
                                    {data.status}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span className="badge badge-light-primary">
                                    {data.status}
                                  </span>
                                </td>
                              )}
                              <td>
                                <img
                                  src={editIcon}
                                  alt="edit"
                                  height={14}
                                  width={14}
                                  data-test-id={data.id}
                                  onClick={this.handleEditClick}
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    data.status === "Active"
                                      ? deleteIcon
                                      : reactiveIcon
                                  }
                                  alt="status icon"
                                  height={18}
                                  width={18}
                                  data-test-id={data.id}
                                  onClick={this.onUpdate}
                                />
                              </td>
                              <td>
                                <img
                                  src={viewIcon}
                                  alt="view"
                                  height={18}
                                  width={18}
                                  data-test-id={data.id}
                                  onClick={this.handleQuestionDetailsClick}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="2">
                      <Table
                        ref="tbl"
                        striped
                        hover
                        responsive
                        bordered
                        id="data-table-zero"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Question </th>
                            <th>Category Name </th>
                            <th>Language </th>
                            <th>Last Edit </th>
                            <th>Status </th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activeQuestionData.map((data, i) => (
                            <tr key={i}>
                              <td>{indexOfFirstItem + i + 1}</td>
                              <td>{data.name}</td>
                              <td>{data.category}</td>
                              <td>{data.language}</td>
                              <td>{formatDateAndTime(data.last_edit)}</td>
                              {data.status === "Active" ? (
                                <td>
                                  <span className="badge badge-light-info">
                                    {data.status}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span className="badge badge-light-primary">
                                    {data.status}
                                  </span>
                                </td>
                              )}
                              <td>
                                <img
                                  src={editIcon}
                                  alt="edit"
                                  height={14}
                                  width={14}
                                  data-test-id={data.id}
                                  onClick={this.handleEditClick}
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    data.status === "Active"
                                      ? deleteIcon
                                      : reactiveIcon
                                  }
                                  alt="status icon"
                                  height={18}
                                  width={18}
                                  data-test-id={data.id}
                                  onClick={this.onUpdate}
                                />
                              </td>
                              <td>
                                <img
                                  src={viewIcon}
                                  alt="view"
                                  height={18}
                                  width={18}
                                  data-test-id={data.id}
                                  onClick={this.handleQuestionDetailsClick}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane tabId="3">
                      <Table
                        ref="tbl"
                        striped
                        hover
                        responsive
                        bordered
                        id="data-table-zero"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Question </th>
                            <th>Category Name </th>
                            <th>Language </th>
                            <th>Last Edit </th>
                            <th>Status </th>
                            <th>Edit</th>
                            <th>Delete</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {inActiveQuestionData.map((data, i) => (
                            <tr key={i}>
                              <td>{indexOfFirstItem + i + 1}</td>
                              <td>{data.name}</td>
                              <td>{data.category}</td>
                              <td>{data.language}</td>
                              <td>{formatDateAndTime(data.last_edit)}</td>
                              {data.status === "Active" ? (
                                <td>
                                  <span className="badge badge-light-info">
                                    {data.status}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span className="badge badge-light-primary">
                                    {data.status}
                                  </span>
                                </td>
                              )}
                              <td>
                                <img
                                  src={editIcon}
                                  alt="edit"
                                  height={14}
                                  width={14}
                                  data-test-id={data.id}
                                  onClick={this.handleEditClick}
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    data.status === "Active"
                                      ? deleteIcon
                                      : reactiveIcon
                                  }
                                  alt="status icon"
                                  height={18}
                                  width={18}
                                  data-test-id={data.id}
                                  onClick={this.onUpdate}
                                />
                              </td>
                              <td>
                                <img
                                  src={viewIcon}
                                  alt="view"
                                  height={18}
                                  width={18}
                                  data-test-id={data.id}
                                  onClick={this.handleQuestionDetailsClick}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                </CardBody>
                <CardFooter>
                  <Pagination
                    currentPage={currentPage}
                    isDisabledNext={isDisabledNext}
                    totalPages={""}
                    onPageChange={this.handlePageChange}
                  />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  listQuestion: questionOperations.listQuestion,
  updateQuestionStatus: questionOperations.updateQuestionStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
