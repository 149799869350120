import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const listReducer = createReducer([])({
    [types.FETCH_PRODUCT_RENEWAL_LIST_COMPLETED]: (state, action) => {
        return action.payload.payload
    },
    [types.FETCH_PRODUCT_RENEWAL_NAME_LIST_COMPLETED]: (state, action) => {
        return action.payload.payload
    },
    [types.INSERT_PRODUCT_RENEWAL_PRICE_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
    [types.UPDATE_PRODUCT_RENEWAL_COMPLETED]: (state, action) => {
        const renewal = action.payload.payload
        return state.map(e => e.id === renewal.id ? renewal : e)
    },     
    [types.UPDATE_PRODUCT_RENEWAL_STATUS_COMPLETED]: (state, action) => {
        const renewal = action.payload.payload
        return state.map(e => e.id === renewal.id ? renewal : e)
    },  
    [types.DELETE_PRODUCT_RENEWAL_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
});

export default combineReducers({
    list: listReducer
});